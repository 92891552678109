<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Solicitação de Reembolso</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="col-md-12">
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="Name">
                    <span class="required">*</span> Nome do Colaborador:
                  </label>
                  <v-select
                    v-model="reimbursement_solicitation.user_id"
                    :options="users"
                    :reduce="(users) => users.id"
                    :label="'name'"
                  ></v-select>
                </div>
                <div class="form-group col-md-5">
                  <label
                    ><span class="required">*</span> Valor do
                    adiantamento</label
                  >
                  <input
                    v-model="advance_amount"
                    type="text"
                    class="form-control"
                    v-money="moneyConfig"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="date"> Data: </label>
                  <input
                    autocomplete="new-password"
                    class="form-control"
                    id="date"
                    placeholder=""
                    type="date"
                    v-model="reimbursement_solicitation.date"
                    disabled
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label><span class="required">*</span> Banco</label>
                  <input
                    v-model="reimbursement_solicitation.deposit_bank"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label><span class="required">*</span> Âgencia</label>
                  <input
                    v-model="reimbursement_solicitation.branch"
                    type="text"
                    class="form-control"
                    v-mask="'9999'"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label>Conta Corrente</label>
                  <input
                    v-model="reimbursement_solicitation.checking_account"
                    type="text"
                    class="form-control"
                    v-mask="'99999999-9'"
                  />
                </div>
              </div>
              <hr
                style="
                  margin-top: 50px;
                  width: 100%;
                  height: 1px;
                  background-color: #dddddd;
                "
              />

              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="card-title">Despesas</div>
                  <div class="row mt-1">
                    <div class="form-group col-md-2">
                      <label
                        ><span class="required">*</span> Data da Despesa</label
                      >
                      <input
                        v-model="date_reimbursement"
                        type="date"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-5">
                      <label for="reimbursement_type">Tipo de Despesa:</label>
                      <v-select
                        v-model="reimbursement_type_id"
                        :options="reimbursement_types"
                        :reduce="
                          (reimbursement_types) => reimbursement_types.id
                        "
                        :label="'name'"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-3" v-show="isRateio">
                      <label>Centro de Custo</label>
                      <v-select
                        v-model="bill_center_ids"
                        :options="bill_centers"
                        :reduce="(bill_center) => bill_center.id"
                        :label="'number_name'"
                        multiple
                      ></v-select>
                    </div>
                    <div class="form-group col-md-3" v-show="!isRateio">
                      <label>Centro de Custo</label>
                      <v-select
                        v-model="bill_center_id"
                        :options="bill_centers"
                        :reduce="(bill_centers) => bill_centers.id"
                        :label="'number_name'"
                      ></v-select>
                    </div>
                    <div class="col-md-2 mt-2">
                      <div class="custom-control custom-switch">
                        <input
                          autocomplete="new-password"
                          placeholder=""
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                          v-model="isRateio"
                        />
                        <label class="custom-control-label" for="customSwitch1"
                          >É rateio?</label
                        >
                      </div>
                    </div>
                  </div>
                  <label for="expense_description">
                    <span class="required">*</span> Descrição da Despesa
                  </label>
                  <textarea
                    v-model="expense_description"
                    class="form-control"
                    id="expense_description"
                    cols="20"
                    rows="3"
                  ></textarea>
                  <div class="row mt-1">
                    <div class="form-group col-md-12">
                      <label><span class="required">*</span> Valor</label>
                      <input
                        v-model="amount"
                        type="text"
                        class="form-control"
                        v-money="moneyConfig"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-1">
                      <input type="file" id="images" ref="file" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-1 text-right">
                  <button class="btn btn-primary" @click="incluirReembolso">
                    <i class="fas fa-plus"></i> Incluir Despesa
                  </button>
                </div>
              </div>

              <div class="row mt-1" v-if="reimbursements.length > 0">
                <div class="col-md-12">
                  <table class="table table-bordered" style="width: 100%">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 20%">Data da Despesa</th>
                        <th scope="col" style="width: 20%">Tipo da Despesa</th>
                        <th scope="col" style="width: 20%">
                          Descrição da Despesa
                        </th>
                        <th scope="col" style="width: 20%">Centro de Custo</th>
                        <th scope="col" style="width: 20%">Valor</th>
                        <th scope="col" style="width: 20%">Arquivos</th>
                        <th scope="col" style="width: 20%">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(reimbursement, index) in reimbursements"
                        :key="index"
                      >
                        <td>{{ reimbursement.date_reimbursement }}</td>
                        <td>
                          {{
                            getReimbursementTypeName(
                              reimbursement.reimbursement_type_id
                            )
                          }}
                        </td>
                        <td>{{ reimbursement.expense_description }}</td>
                        <td
                          v-if="
                            Array.isArray(reimbursement.bill_center_ids) &&
                            reimbursement.bill_center_ids.length > 0
                          "
                        >
                          {{
                            getBillCenterNames(reimbursement.bill_center_ids)
                          }}
                        </td>
                        <td
                          v-else-if="
                            reimbursement.bill_center_id !== null &&
                            reimbursement.bill_center_id !== undefined
                          "
                        >
                          {{ getBillCenterName(reimbursement.bill_center_id) }}
                        </td>
                        <td
                          v-if="
                            reimbursement.bill_center_ids.length === 0 &&
                            !reimbursement.bill_center_id
                          "
                        >
                          Centro de custo não disponível
                        </td>
                        <td>{{ formatCurrency(reimbursement.amount) }}</td>
                        <td>
                          {{
                            reimbursement && reimbursement.files
                              ? reimbursement.files.name
                              : "Nenhum Arquivo"
                          }}
                        </td>
                        <td>
                          <button
                            class="btn btn-crud delete"
                            @click="remove(index)"
                          >
                            <i
                              class="far fa-trash-alt"
                              data-toggle="tooltip"
                              title="Excluir"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row mb-3" v-else>
                <div class="col-md-12">Nenhuma despesa informada</div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    ><span class="required">*</span> Saldo a receber
                    (devolver)</label
                  >
                  <input
                    :value="
                      formatCurrency(
                        reimbursement_solicitation.balance_to_receive
                      )
                    "
                    type="text"
                    class="form-control"
                    v-money="moneyConfig"
                    disabled
                  />
                  <span
                    v-if="reimbursement_solicitation.balance_to_receive < 0"
                    class="text-danger"
                    >(Devolver a IT4D)</span
                  >
                  <span
                    v-else-if="
                      reimbursement_solicitation.balance_to_receive > 0
                    "
                    class="text-success"
                    >(Devolver ao Colaborador)</span
                  >
                </div>
                <div class="form-group col-md-6">
                  <label
                    ><span class="required">*</span> Total dos gastos</label
                  >
                  <input
                    :value="
                      formatCurrency(reimbursement_solicitation.total_expenses)
                    "
                    type="text"
                    class="form-control"
                    v-money="moneyConfig"
                    disabled
                  />
                </div>
              </div>

              <span class="required_fields">
                <span class="required">*</span>
                <strong>Campos obrigatórios</strong>
              </span>
              <div class="block text-right mb-1">
                <router-link
                  to="/reimbursement_solicitations"
                  class="btn btn-back"
                >
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <a class="btn btn-add ml-1" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import Money from "v-money";

export default {
  name: "imageUpload",
  data() {
    return {
      isRateio: false,
      bill_center_ids: [],
      moneyConfig: {
        prefix: "R$ ",
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      reimbursement_solicitation: {
        user_id: "",
        date: "",
        checking_account: "",
        balance_to_receive: 0,
        branch: "",
        deposit_bank: "",
        bill_center_id: "",
        total_expenses: 0,
        approve: 0,
      },
      reimbursements: [],
      reimbursement_types: [],
      expense_description: "",
      reimbursement_solicitation_id: "",
      reimbursement_type_id: "",
      bill_center_id: "",
      amount: "",
      advance_amount: "",
      advance_amount: "",
      date_reimbursement: "",
      file: "",
      bill_centers: [],
      users: [],
      base64: "",
    };
  },
  computed: {},
  watch: {
    isRateio(newVal, oldVal) {
      if (newVal) {
        this.bill_center_id = null;
      } else {
        this.bill_center_ids = [];
      }
    },
  },
  methods: {
    getReimbursementTypeName(reimbursementTypeId) {
      const reimbursementType = this.reimbursement_types.find(
        (type) => type.id === reimbursementTypeId
      );

      return reimbursementType ? reimbursementType.name : "Tipo não encontrado";
    },

    getBillCenterNames(billCenterIds) {
      const ids = Array.isArray(billCenterIds)
        ? billCenterIds
        : billCenterIds.split(",").map((id) => parseInt(id, 10));

      const names = ids.map((id) => {
        const billCenter = this.bill_centers.find(
          (bill_center) => bill_center.id === id
        );
        return billCenter
          ? billCenter.number_name
          : "Centro de custo não encontrado";
      });

      return names.join(", ");
    },

    getBillCenterName(billCenterId) {
      const billCenter = this.bill_centers.find(
        (bill_center) => bill_center.id === billCenterId
      );

      return billCenter
        ? billCenter.number_name
        : "Centro de custo não encontrado";
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    convertCurrencyToNumber(currencyString) {
      const cleanedString = currencyString
        .replace(/\s|R\$/g, "")
        .replace(".", "");

      const dotFormattedString = cleanedString.replace(",", ".");

      const numberValue = parseFloat(dotFormattedString);

      return isNaN(numberValue) ? 0 : numberValue;
    },

    remove(index) {
      const self = this;
      self.reimbursements.splice(index, 1);

      self.calculateTotalExpenses();
      self.calculateBalanceToReceive();
    },

    incluirReembolso() {
      const self = this;

      let fileInput = document.getElementById("images");
      const file = fileInput.files[0];

      if (this.advance_amount == "") {
        this.advance_amount = this.advance_amount;
      }

      if (this.reimbursement_type_id === "") {
        this.$message(null, "O tipo da despesa é obrigatório", "error");
        return;
      }

      if (this.date_reimbursement === "") {
        this.$message(null, "A data da despesa é obrigatória", "error");
        return;
      }

      if (!file) {
        this.$message(null, "O arquivo é obrigatório", "error");
        return;
      }

      const today = new Date();
      const reimbursementDate = new Date(this.date_reimbursement);

      if (reimbursementDate > today) {
        this.$message(
          null,
          "A data do despesa não pode ser maior que hoje",
          "error"
        );
        return;
      }

      if (this.expense_description === "") {
        this.$message(null, "A descrição da despesa é obrigatória", "error");
        return;
      }

      if (this.isRateio) {
        if (
          !Array.isArray(this.bill_center_ids) ||
          this.bill_center_ids.length === 0
        ) {
          this.$message(null, "Selecione ao menos um centro de custo", "error");
          return;
        }
      } else {
        if (!this.bill_center_id) {
          this.$message(null, "O centro de custo é obrigatório", "error");
          return;
        }
      }

      const amountNumber = this.convertCurrencyToNumber(this.amount);
      console.log("valor antes da conversão:", this.amount);
      console.log("valor depois da conversão:", amountNumber);
      const advanceAmountNumber = this.convertCurrencyToNumber(
        this.advance_amount
      );

      const newReimbursement = {
        reimbursement_type_id: self.reimbursement_type_id,
        expense_description: self.expense_description,
        amount: amountNumber,
        advance_amount: advanceAmountNumber,
        date_reimbursement: self.date_reimbursement,
        files: file,
        bill_center_id: self.bill_center_id,
        bill_center_ids: self.bill_center_ids,
      };

      self.reimbursements.push(newReimbursement);

      console.log("oi", newReimbursement);

      self.expense_description = "";
      self.reimbursement_type_id = "";
      self.amount = "";
      // this.advance_amount = "";
      self.$refs.file.value = "";
      self.date_reimbursement = "";
      self.bill_center_id = "";
      self.bill_center_ids = [];
      self.isRateio = false;

      self.calculateTotalExpenses();
      self.calculateBalanceToReceive();
    },

    makeFormData() {
      const fd = new FormData();

      // Adicione outros campos ao FormData, se necessário
      fd.append("_method", "POST");

      return fd;
    },

    save: function () {
      const self = this;

      if (
        this.reimbursement_solicitation.user_id === "" ||
        this.reimbursement_solicitation.user_id === null
      ) {
        this.$message(null, "O Nome é obrigatório", "error");
        return false;
      }

      if (
        this.reimbursement_solicitation.date === "" ||
        this.reimbursement_solicitation.date === null
      ) {
        this.$message(null, "A Data é obrigatória", "error");
        return false;
      }

      if (
        this.reimbursement_solicitation.deposit_bank === "" ||
        this.reimbursement_solicitation.deposit_bank === null
      ) {
        this.$message(null, "O Banco é obrigatório", "error");
        return false;
      }

      if (
        this.reimbursement_solicitation.branch === "" ||
        this.reimbursement_solicitation.branch === null
      ) {
        this.$message(null, "A Agência é obrigatória", "error");
        return false;
      }

      if (
        this.reimbursement_solicitation.checking_account === "" ||
        this.reimbursement_solicitation.checking_account === null
      ) {
        this.$message(null, "A Conta Corrente é obrigatória", "error");
        return false;
      }

      if (this.reimbursement_solicitation.total_expenses === 0) {
        this.$message(
          null,
          "O Total não pode ser 0, favor informar um Valor.",
          "error"
        );
        return false;
      }

      let api = self.$store.state.api + "reimbursement_solicitations";
      let fd = self.makeFormData();

      if (self.reimbursement_solicitation.id) {
        api += "/" + self.reimbursement_solicitation.id;
        self.reimbursement_solicitation._method = "PUT";
      }

      fd.append(
        "reimbursement_solicitation[user_id]",
        self.reimbursement_solicitation.user_id
      );
      fd.append(
        "reimbursement_solicitation[date]",
        self.reimbursement_solicitation.date
      );
      fd.append(
        "reimbursement_solicitation[checking_account]",
        self.reimbursement_solicitation.checking_account
      );
      fd.append(
        "reimbursement_solicitation[balance_to_receive]",
        self.reimbursement_solicitation.balance_to_receive
      );
      fd.append(
        "reimbursement_solicitation[branch]",
        self.reimbursement_solicitation.branch
      );
      fd.append(
        "reimbursement_solicitation[deposit_bank]",
        self.reimbursement_solicitation.deposit_bank
      );
      fd.append(
        "reimbursement_solicitation[total_expenses]",
        self.reimbursement_solicitation.total_expenses
      );
      fd.append(
        "reimbursement_solicitation[approve]",
        self.reimbursement_solicitation.approve
      );

      self.reimbursements.forEach((element, index) => {
        fd.append(
          "reimbursements[" + index + "][reimbursement_type_id]",
          element.reimbursement_type_id
        );
        fd.append(
          "reimbursements[" + index + "][expense_description]",
          element.expense_description
        );
        fd.append("reimbursements[" + index + "][amount]", element.amount);
        fd.append(
          "reimbursements[" + index + "][advance_amount]",
          element.advance_amount
        );
        fd.append(
          "reimbursements[" + index + "][date_reimbursement]",
          element.date_reimbursement
        );

        // Adicione apenas o campo relevante
        if (element.bill_center_id) {
          fd.append(
            "reimbursements[" + index + "][bill_center_id]",
            element.bill_center_id
          );
        }
        element.bill_center_ids.forEach((id) => {
          fd.append("reimbursements[" + index + "][bill_center_ids][]", id);
        });
        fd.append("reimbursements[" + index + "][files]", element.files);
      });

      self.$loading(true);

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            "Informações guardadas com sucesso",
            "success"
          );
          this.$router.push("/reimbursement_solicitations");
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    calculateTotalExpenses() {
      let totalExpenses = this.reimbursements.reduce((total, reimbursement) => {
        const amount = parseFloat(reimbursement.amount);
        return isNaN(amount) ? total : total + amount;
      }, 0);

      this.reimbursement_solicitation.total_expenses = totalExpenses;
    },

    calculateBalanceToReceive() {
      this.calculateTotalExpenses();

      this.reimbursement_solicitation.balance_to_receive =
        this.reimbursement_solicitation.total_expenses -
        this.convertCurrencyToNumber(this.advance_amount);
    },

    getReimbursementSolicitations: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "reimbursement_solicitations/" +
        id +
        "?with[]=attachments&with[]=reimbursements";

      axios
        .get(api)
        .then((response) => {
          self.reimbursement_solicitation = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getReimbursementTypes: function () {
      const self = this;
      const api = self.$store.state.api + "reimbursement_types/";

      axios
        .get(api)
        .then((response) => {
          self.reimbursement_types = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getReimbursements: function (solicitation_id) {
      const self = this;

      let api =
        self.$store.state.api +
        `reimbursement_solicitations/${solicitation_id}/reimbursements`;

      axios
        .get(api)
        .then((response) => {
          self.reimbursements = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getBillCenters: function () {
      const self = this;
      const api = self.$store.state.api + "bill_centers/";

      axios
        .get(api)
        .then((response) => {
          self.bill_centers = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users_solicitation";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.users;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getReimbursementTypes();
    self.getBillCenters();
    self.getUsers();

    if (id) {
      self.getReimbursementSolicitations(id);
      self.getReimbursements(id);
    } else {
      self.reimbursement_solicitation.date = moment().format("YYYY-MM-DD");
    }
  },
  components: {
    BaseCrud,
    vSelect,
    Money,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.attachment_url {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.attachment_url-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>