import Crud from '../views/ReasonsDelete/Crud'
import Entity from '../views/ReasonsDelete/Add'


let routes = {
    path: '/reasons_delete',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/reasons_delete/add',
            name: 'NewReasonsDelete',
            component: Entity,
            meta: {
                pageTitle: 'Motivos Delete',
                breadcrumb: [
                    {
                        text: 'Motivos Delete',
                        active: false,
                        to: '/reasons_delete',
                    },
                    {
                        text: 'Adicionar Motivo',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reasons_delete/edit/:id?',
            name: 'EditReasonsDelete',
            component: Entity,
            meta: {
                pageTitle: 'Motivos Delete',
                breadcrumb: [
                    {
                        text: 'Motivos Delete',
                        active: false,
                        to: '/reasons_delete',
                    },
                    {
                        text: 'Editar Motivo Delete',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reasons_delete',
            name: 'list-reasons-delete-calls',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Motivos Delete',
                        active: true,
                    },
                ],
            },
        },
    ]
};

export default routes
