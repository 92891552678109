import Crud from '../views/HomeOffice/Crud'
import Entity from '../views/HomeOffice/Add'
import Calendario from '../views/HomeOffice/Calendario'


let routes = {
    path: '/home_offices',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/home_offices/add',
            name: 'NewHomeOffice',
            component: Entity,
            meta: {
                pageTitle: 'Escala Home Office',
                breadcrumb: [
                    {
                        text: 'Escala Home Office',
                        active: false,
                        to: '/home_offices',
                    },
                    {
                        text: 'Adicionar escala',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/calendario',
            name: 'calendario',
            component: Calendario,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Calendário',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/home_offices/edit/:id?',
            name: 'EditHomeOffice',
            component: Entity,
            meta: {
                pageTitle: 'Home Office',
                breadcrumb: [
                    {
                        text: 'Escala Home Office',
                        active: false,
                        to: '/home_offices',
                    },
                    {
                        text: 'Editar escala',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/home_offices',
            name: 'list-home_offices',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Escala Home Office',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
