<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-8">
                <label>Motivos Delete</label>
                <input
                  v-model="reasons_delete.title"
                  id="name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-4">
                <label>Status</label>
                <select v-model="reasons_delete.status" class="form-control">
                  <option :value="1">Ativo</option>
                  <option :value="0">Inativo</option>
                </select>
              </div>
              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/reasons_delete" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      reasons_delete: {
        status: "1",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "reasons_delete";

      if (
        self.reasons_delete.title == "" ||
        self.reasons_delete.title == undefined
      ) {
        self.$message(
          "Error",
          `Titulo deve ser preenchido corretamente!`,
          "error"
        );
        return false;
      }

      if (self.reasons_delete.id) {
        api += "/" + self.reasons_delete.id;
        axios
          .put(api, self.reasons_delete)
          .then((response) => {
            self.$message("Sucesso", `Motivo Atualizado`, "success");
            this.$router.push("/reasons_delete");
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      } else {
        axios
          .post(api, self.reasons_delete)
          .then((response) => {
            self.$message(
              "Sucesso",
              `Informações guardadas com sucesso`,
              "success"
            );
            this.$router.push("/reasons_delete");
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }
    },
    getReasonsDelete: function (id) {
      const self = this;
      const api = self.$store.state.api + "reasons_delete/" + id;

      axios
        .get(api)
        .then((response) => {
          self.reasons_delete = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },

  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getReasonsDelete(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
