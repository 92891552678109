<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-3">
                <label><span class="required">*</span> Cliente</label>
                <v-select
                  v-model="call.client_id"
                  :options="clients"
                  :reduce="(clients) => clients.id"
                  :label="'company_name'"
                  @change="getProjects"
                ></v-select>
              </div>
              <div class="col-md-3" v-if="call.client_id">
                <label
                  ><span class="required">*</span> Contato do cliente</label
                >
                <v-select
                  v-model="call.client_contact_id"
                  :options="client_contacts"
                  :reduce="(client_contacts) => client_contacts.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-3" v-if="call.client_id">
                <label><span class="required">*</span> Sistema</label>
                <v-select
                  v-model="call.system_error_id"
                  :options="projects"
                  :reduce="(project) => project.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-3">
                <label>Usuário Logado</label>
                <input
                  v-model="call.user"
                  id="name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-3">
                <label>Módulo</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  placeholder="Ex. Dashboard, Usuário, etc"
                  v-model="call.method_error"
                />
              </div>
              <div class="col-md-3">
                <label>Método</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  placeholder="Ex. Criar, Editar, etc..."
                  v-model="call.request_error"
                />
              </div>
              <div class="col-md-3">
                <label
                  ><span class="required">*</span> Data e hora do Chamado</label
                >
                <div>
                  <input
                    v-model="call.data_error"
                    id="name"
                    class="form-control"
                    type="datetime-local"
                    max="9999-12-31T23:59"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label><span class="required">*</span> Tipo do Chamado</label>
                <v-select
                  v-model="call.type_call_id"
                  :options="type_calls"
                  :reduce="(type_calls) => type_calls.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-12 mt-1">
                <label><span class="required">*</span> Título do Chamado</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  placeholder="Ex.: Cadastrar novo usuário no sistema de Pesquisa de Satisfação"
                  v-model="call.title"
                />
              </div>
              <div class="col-md-12 mt-1">
                <label
                  ><span class="required">*</span> Por favor, descreva sua
                  solicitação abaixo com o máximo de detalhes possível</label
                >
                <ckeditor
                  v-model="call.description_error"
                  :config="editorConfig"
                  :editor="editor"
                />
              </div>
            </div>
            <div class="col-md-12 text-right">
              <hr />
              <router-link to="/calls" class="btn btn-back mr-1">
                <i class="fa fa-arrow-left" />
                Voltar
              </router-link>
              <a class="btn btn-add" @click="save">
                Salvar
                <i class="fa fa-save" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.intranet-dev.it4d.com.br/api/v1/images/upload",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  name: "imageUpload",
  data() {
    return {
      call: {
        dev_acting: "0",
        dev_accompany: "0",
        status: "1",
        url_system: "",
        system_error_id: null,
        data_error: "",
      },
      projects: [],
      users: [],
      clients: [],
      client_contacts: [],
      editor: ClassicEditor,
      editorConfig: {
        width: "800px",
        height: "400px",
        extraPlugins: [MyUploadAdapterPlugin],
      },
      type_calls: [],
    };
  },
  computed: {},
  watch: {
    "call.client_id": function (new_client_id) {
      this.getClientContacts(new_client_id);
      this.getProjects(new_client_id);
    },
  },
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "calls";

      if (self.call.id) {
        api += "/" + self.call.id;
      }

      self.$loading(true);

      axios
        .post(api, self.call)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/calls");
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getCall: function (id) {
      const self = this;
      const api = self.$store.state.api + "calls/" + id;

      axios
        .get(api)
        .then((response) => {
          self.call = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjects: function () {
      const self = this;

      if (!self.call.client_id) {
        return;
      }

      let api =
        self.$store.state.api + `clients/${self.call.client_id}/projects`;

      axios
        .get(api)
        .then((response) => {
          const projects = response.data.projects;
          self.projects = projects;
          self.call.system_error_id = projects.id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getClients: function () {
      const self = this;
      const api = self.$store.state.api + "clients/";

      axios
        .get(api)
        .then((response) => {
          self.clients = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getClientContacts: function (client_id) {
      const self = this;
      let api = self.$store.state.api + "client_contacts/";

      if (client_id) {
        api = `${api}?client_id=${client_id}`;
      }

      axios
        .get(api)
        .then((response) => {
          self.client_contacts = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTypeCalls: function () {
      const self = this;
      const api = self.$store.state.api + "type_calls/";

      axios
        .get(api)
        .then((response) => {
          self.type_calls = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },

  created() {
    this.getClientContacts();
    this.getProjects();
  },

  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    self.getClients();
    self.getTypeCalls();

    if (id) {
      self.getCall(id);
    } else {
      self.call.data_error = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>