<template>
  <div class="main-content">
    <div class="row mb-3">
      <div class="col-md-3">
        <label>Data de Ínicio</label>
        <input type="date" class="form-control" v-model="filters.data_inicio" />
      </div>
      <div class="col-md-3">
        <label>Data do Fim</label>
        <input type="date" class="form-control" v-model="filters.data_fim" />
      </div>
      <div class="col-md-12 mt-2 text-end">
        <button type="button" class="btn btn-primary" @click="filterData">
          Filtrar
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" style="height: 585px">
        <div class="card p-1 pb-2" style="height: 100%">
          <div class="row">
            <div class="col-md-12 text-align-center">
              <h2
                class="text-center mb-4"
                v-if="filters.data_inicio != '' && filters.data_fim != ''"
              >
                Gráfico de fechamento de chamados de
                {{ filters.data_inicio }} a
                {{ filters.data_fim }}
              </h2>
              <h2 class="text-center mb-4" v-else>
                Gráfico de fechamento de chamados da semana
              </h2>
              <div class="container text-align-center">
                <div class="row">
                  <div v-if="loading" class="loader">Carregando...</div>
                  <div v-else class="chart-container">
                    <BarChart
                      :chartData="chartDataFromAPI"
                      :chartOptions="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card p-1 pb-2">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Chamados finalizados de {{ filters.data_inicio }} a
            {{ filters.data_fim }} por Devs
          </h2>
          <h2 class="text-center mb-4" v-else>
            Chamados finalizados na semana por Devs
          </h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <!-- <th>Posição</th> -->
                  <th>Usuário</th>
                  <th>Chamados finalizados</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dev in ticketsDone" :key="dev.id">
                  <!-- <td>
                    <template v-if="index === 0">
                      <i class="fas fa-trophy gold-icon"></i>
                    </template>
                    <template v-else-if="index === 1">
                      <i class="fas fa-star silver-icon"></i>
                    </template>
                    <template v-else-if="index === 2">
                      <i class="fas fa-star bronze-icon"></i>
                    </template>
                  </td> -->
                  <td>{{ dev.dev ? dev.dev.name : "" }}</td>
                  <td>{{ dev.total_tickets }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-1 pb-2">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Chamados finalizados de {{ filters.data_inicio }} a
            {{ filters.data_fim }} por Projeto
          </h2>
          <h2 class="text-center mb-4" v-else>
            Chamados finalizados na semana por Projeto
          </h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Projeto</th>
                  <th>Chamados finalizados</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="project in ticketsByProject"
                  :key="project.project_id"
                >
                  <td>{{ project.project ? project.project.name : "" }}</td>
                  <td>{{ project.total_tickets }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-1 pb-2 mt-4">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Chamados finalizados de {{ filters.data_inicio }} a
            {{ filters.data_fim }}
          </h2>
          <h2 class="text-center mb-4" v-else>
            Chamados finalizados na semana
          </h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Dev</th>
                  <th>Projeto</th>
                  <th>Quando foi finalizado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in allTicketsDone" :key="ticket.id">
                  <td>{{ ticket.id }}</td>
                  <td>{{ ticket.dev ? ticket.dev.name : "" }}</td>
                  <td>{{ ticket.project ? ticket.project.name : "" }}</td>
                  <td>{{ ticket.closed_at_f }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "@/components/BarChart.vue";
import { Pagination } from "vue-pagination-2";

export default {
  components: {
    BarChart,
    Pagination,
  },
  data() {
    return {
      filters: { data_inicio: "", data_fim: "" },
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      pages: [],
      chartDataFromAPI: {
        labels: [],
        datasets: [
          {
            label: "Chamados Finalizados",
            backgroundColor: "#4F81BD",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      loading: false,
      ticketsDone: [],
      allTicketsDone: [],
      ticketsByProject: [],
    };
  },
  methods: {
    getGraphicTicket() {
      const self = this;

      const params = {
        data_inicio: self.filters.data_inicio,
        data_fim: this.filters.data_fim
          ? this.filters.data_fim + " 23:59:59"
          : null,
      };

      self.$loading(true);

      self.loading = true;

      axios
        .get(this.$store.state.api + "graphic_tickets", { params })
        .then((response) => {
          const tickets = response.data;

          self.chartDataFromAPI.labels = tickets.map(
            (ticket) => ticket.project_name
          );
          self.chartDataFromAPI.datasets[0].data = tickets.map(
            (ticket) => ticket.total
          );

          self.loading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTicketDones() {
      const self = this;
      const api = self.$store.state.api + "ticketsDone";

      const params = {
        data_inicio: self.filters.data_inicio,
        data_fim: this.filters.data_fim
          ? this.filters.data_fim + " 23:59:59"
          : null,
      };

      axios
        .get(api, { params })
        .then((response) => {
          if (response.data) {
            if (
              response.data.tickets_by_dev &&
              Array.isArray(response.data.tickets_by_dev)
            ) {
              self.ticketsDone = response.data.tickets_by_dev.sort(
                (a, b) => b.total_tickets - a.total_tickets
              );
            }
            if (
              response.data.all_tickets &&
              Array.isArray(response.data.all_tickets)
            ) {
              self.allTicketsDone = response.data.all_tickets;
            }
            if (
              response.data.tickets_by_project &&
              Array.isArray(response.data.tickets_by_project)
            ) {
              self.ticketsByProject = response.data.tickets_by_project.sort(
                (a, b) => b.total_tickets - a.total_tickets
              );
            }
            self.$loading(false);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    filterData() {
      this.getGraphicTicket();
      this.getTicketDones();
    },
  },
  mounted() {
    this.getGraphicTicket();
    this.getTicketDones();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.main-content {
  margin-top: 20px; /* Ajuste conforme necessário */
}

.container {
  width: 100%;
}

.chart-container {
  width: 100%; /* Utilize 100% para ocupar a largura completa da coluna */
}

.loader {
  font-size: 3vh;
  font-weight: 700;
  text-align: center;
  padding: 2em;
}

.gold-icon {
  color: gold;
}

.silver-icon {
  color: silver;
}

.bronze-icon {
  color: #cd7f32;
}
</style>
