import Crud from '../views/Dasp/Crud'
import Entity from '../views/Dasp/Add'
import View from '../views/Dasp/View'
import Double from '../views/Dasp/Double'

let routes = {
    path: '/dasps',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/dasps/add',
            name: 'NewDasp',
            component: Entity,
            meta: {
                pageTitle: 'DASP',
                breadcrumb: [
                    {
                        text: 'DASP',
                        active: false,
                        to: '/dasps',
                    },
                    {
                        text: 'Adicionar DASP',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/dasps/double/:id?',
            name: 'DoubleDasp',
            component: Double,
            meta: {
                pageTitle: 'DASP',
                breadcrumb: [
                    {
                        text: 'DASP',
                        active: false,
                        to: '/dasps',
                    },
                    {
                        text: 'Adicionar DASP',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/dasps/view/:id?',
            name: 'ViewDasp',
            component: View,
            meta: {
                pageTitle: 'DASP',
                breadcrumb: [
                    {
                        text: 'DASP',
                        active: false,
                        to: '/dasps',
                    },
                    {
                        text: 'Visualizar DASP',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/dasps',
            name: 'list-dasps',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'DASP',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
