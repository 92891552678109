<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-12 mb-1">
                <label>Tarefa</label>
                <input
                  v-model="issue.summary"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-3 mb-1">
                <label>Sistema</label>
                <v-select
                  v-model="issue.project_id"
                  :options="projects"
                  :reduce="(projects) => projects.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-3 mb-1">
                <label>Dev Responsável</label>
                <v-select
                  v-model="issue.user_id"
                  :options="users"
                  :reduce="(users) => users.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-2 mb-1">
                <label>Faturável</label>
                <select v-model="issue.factorable" class="form-control">
                  <option :value="1">Sim</option>
                  <option :value="0">Não</option>
                </select>
              </div>
              <div class="col-md-2 mb-1">
                <label>Horas</label>
                <input
                  v-model="issue.original_estimate"
                  class="form-control"
                  type="number"
                />
              </div>
              <div class="col-md-2 mb-1" v-show="issue.id">
                <label>Status</label>
                <v-select
                  v-model="issue.status"
                  :options="statuses"
                  :reduce="(status) => status.value"
                  :label="'label'"
                ></v-select>
              </div>
              <div class="col-md-12 mb-1">
                <label>Descrição</label>
                <textarea
                  class="form-control"
                  v-model="issue.description"
                ></textarea>
              </div>

              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/tasks" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      issue: {
        original_estimate: 0,
        total_spent: 0,
        is_subtask: 0,
        priority: 0,
        issue_key: "",
        issue_id: "",
        status: "todo",
        dev_acting: "",
        factorable: 0,
      },
      projects: [],
      users: [],
      statuses: [
        { label: "To Do", value: "todo" },
        { label: "In Progress", value: "doing" },
        { label: "Done", value: "done" },
      ],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "issues";

      if (self.issue.id) {
        api += "/" + self.issue.id;
        self.issue._method = "PUT";
      }

      self.$loading(true);

      axios
        .post(api, self.issue)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/tasks");
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getIssue: function (id) {
      const self = this;
      const api = self.$store.state.api + "issues/" + id;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.issue = response.data.data[0];
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getProjects: function () {
      const self = this;
      const api = self.$store.state.api + "projects?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getUsers();
    self.getProjects();

    if (id) {
      self.getIssue(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
