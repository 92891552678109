<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Calendário de Escala</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row mb-3 justify-content-center">
                <div
                  class="col-md-12 mb-1"
                  style="font-size: 18px; font-weight: bold; margin-top: -5px"
                >
                  Legenda:
                </div>
                <div class="col-md-0">
                  <div class="quad azul-claro">.</div>
                </div>
                <div class="col-md-2">Presencial</div>
                <div class="col-md-0">
                  <div class="quad verde">.</div>
                </div>
                <div class="col-md-2">Home Office</div>
                <div class="col-md-0">
                  <div class="quad vermelho">.</div>
                </div>
                <div class="col-md-2">Férias</div>
                <div class="col-md-0">
                  <div class="quad laranja">.</div>
                </div>
                <div class="col-md-2">Folga</div>
                <div class="col-md-0">
                  <div class="quad amarelo">.</div>
                </div>
                <div class="col-md-2">Atestado</div>
              </div>
              <section>
                <!-- Content -->
                <div v-if="loadedComponent" class="mr-3 ml-3">
                  <!-- Main Content -->
                  <FullCalendar
                    v-if="loadedComponent"
                    :options="calendarOptions"
                  />
                </div>
                <div v-else class="mt-3 mb-3 centered">
                  Carregando o calendário ...
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="stopover"
      tabindex="-1"
      role="dialog"
      aria-labelledby="stopover"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Escala dia {{ stopover.date }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body row">
            <div class="col-md-12">
              <label>Deseja alterar o status?</label>
              <select v-model="stopover.status_h" class="form-control">
                <option :value="0">Presencial</option>
                <option :value="1">Home Office</option>
                <option :value="2">Férias</option>
                <option :value="3">Folga</option>
                <option :value="4">Atestado</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="save"
            >
              Alterar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  data() {
    return {
      isLoading: true,
      loadedComponent: false,
      actualDate: moment().format("LL"),
      actualMonth: moment().format("MMMM [de] YYYY"),
      calendarOptions: {
        locale: ptLocale,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "timeGridWeek",
        height: "520px",
        // editable: false,
        // selectable: true,
        // selectMirror: true,
        // dayMaxEvents: true,
        weekends: false,
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        // select: this.handleDateSelect,
        events: [],
        slotMinTime: "09:00:00",
        slotMaxTime: "18:00:00",
      },
      stopover: {
        date: "",
        status_h: "",
      },
    };
  },
  computed: {},
  methods: {
    bringData: function () {
      const self = this;
      const api = self.$store.state.api + "calendario";

      axios
        .post(api, self.filters)
        .then((response) => {
          self.calendarOptions.events = response.data;
          self.loadedComponent = true;
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    handleEventClick(clickInfo) {
      const self = this;
      const api = self.$store.state.api + "home_offices/" + clickInfo.event.id;

      self.stopover = [];

      axios
        .get(api)
        .then((response) => {
          self.stopover = response.data.data[0];
          self.stopover.date = response.data.data[0].start_date_f;
          self.stopover.status_h = response.data.data[0].home_office;
          self.stopover.id = clickInfo.event.id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

      $("#stopover").modal("show");
    },
    save() {
      const self = this;
      const api = self.$store.state.api + "home_offices/" + self.stopover.id;
      self.stopover._method = "PUT";
      self.stopover.home_office = self.stopover.status_h;

      axios
        .post(api, self.stopover)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.bringData();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.bringData();
    // self.$loading(true);
  },
  components: {
    vSelect,
    FullCalendar,
  },
};
</script>
<style scoped>
.quad {
  height: 15px;
  width: 15px;
}

.azul-claro {
  background-color: #28c7cf;
  color: #28c7cf;
}
.verde {
  background-color: #388a15;
  color: #388a15;
}

.vermelho {
  background-color: #ff5230;
  color: #ff5230;
}
.laranja {
  background-color: #ff8615;
  color: #ff8615;
}
.amarelo {
  background-color: #d8d400;
  color: #d8d400;
}
</style>