import Crud from '../views/Projects/Crud'
import Entity from '../views/Projects/Add'
import View from '../views/Projects/View'

let routes = {
    path: '/projects',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/projects/add',
            name: 'NewProjects',
            component: Entity,
            meta: {
                pageTitle: 'Sistema',
                breadcrumb: [
                    {
                        text: 'Sistemas',
                        active: false,
                        to: '/projects',
                    },
                    {
                        text: 'Adicionar Sistema',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/projects/edit/:id?',
            name: 'EditProjects',
            component: Entity,
            meta: {
                pageTitle: 'Sistema',
                breadcrumb: [
                    {
                        text: 'Sistemas',
                        active: false,
                        to: '/projects',
                    },
                    {
                        text: 'Editar Sistema',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/projects/view/:id?',
            name: 'ViewProjects',
            component: View,
        },
        {
            path: '/projects',
            name: 'list-projects',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Sistemas',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
