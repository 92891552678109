<template>
  <div>
    <div class="row">
      <div
        class="col-12 mb-1"
        align="right"
        v-if="$store.state.user.perfil_id == 8"
      >
        <button
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#modalExport"
        >
          <i class="fas fa-file"></i> - Exportar Tarefas em PDF
        </button>
        <div class="ibox-title" style="padding: 16px">
          <div
            class="modal fade"
            id="modalExport"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalExportTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Exportar
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-content modal-lg mt-1">
                  <div class="d-flex justify-content-between">
                    <div class="col-md-6 mb-1">
                      <label>Data de Ínicio</label>
                      <input
                        type="date"
                        v-model="startDate"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-6 mb-1">
                      <label>Data do Fim</label>
                      <input
                        type="date"
                        v-model="endDate"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-6 mb-1">
                      <label>Sistemas</label>
                      <v-select
                        :options="projects"
                        :reduce="(projects) => projects.id"
                        :label="'name'"
                        v-model="selectedProject"
                      ></v-select>
                    </div>
                    <div class="col-md-6 mb-1">
                      <label>Dev Responsável</label>
                      <v-select
                        :options="users"
                        :reduce="(user) => user.id"
                        :label="'name'"
                        v-model="selectedUser"
                      ></v-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-6 mb-1">
                      <label>Valor Hora</label>
                      <input
                        type="number"
                        v-model="valueHour"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="generatePdf"
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'tasksCrud'"
          crudName="Tasks"
          newText="Nova Task"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="false"
          :enableDelete="$store.state.user.perfil_id == 8"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :enableTickets="false"
          :extra="extra"
          @edit="edit"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      users: [],
      projects: [],
      selectedUser: null,
      selectedProject: null,
      valueHour: null,
      startDate: "",
      endDate: "",
      fileUrl: "",
      table: "tasksTable",
      showMessageImport: false,
      tasksCrud: {},
      pages: [],
      url: "",
      columns: ["id", "summary", "project_id", "user_id", "status", "actions"],
      tableData: ["id", "summary", "project_id", "user_id", "status"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["summary", "project_id", "user_id", "status"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          summary: "Título",
          project_id: "Sistema",
          user_id: "Dev Responsável",
          actions: "Ações",
        },
        sortable: ["id", "project_id", "user_id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: "done",
              text: "Concluído",
            },
            {
              id: "doing",
              text: "Em Andamento",
            },
            {
              id: 1,
              text: "Impedido",
            },
            {
              id: "todo",
              text: "Backlog",
            },
          ],
        },
        templates: {
          status: function (h, row, index) {
            switch (row.status) {
              case "todo":
                return (
                  <h5 class="text-center">
                    <span class="badge badge-secondary">Backlog</span>
                  </h5>
                );
              case "":
                return (
                  <h5 class="text-center">
                    <span class="badge badge-danger">Impedido</span>
                  </h5>
                );
              case "doing":
                return (
                  <h5 class="text-center">
                    <span class="badge badge-primary">Em Andamento</span>
                  </h5>
                );
              case "done":
                return (
                  <h5 class="text-center">
                    <span class="badge badge-success">Concluído</span>
                  </h5>
                );
            }
          },
          project_id: function (h, row, index) {
            return row.project ? row.project.name : "Indisponível";
          },
          user_id: function (h, row, index) {
            return row.user ? row.user.name : "Nenhum Dev Alocado";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["project", "user"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud edit",
          title: "Editar",
          name: "edit",
          icon: "fas fa-edit",
        },
      ],
      endPoint: "issues/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    edit(props) {
      const self = this;
      const userId = self.$store.state.user.id;
      const userProfileId = self.$store.state.user.perfil_id;

      if (userId) {
        if (props.row.user_id === userId || userProfileId == 8) {
          self.$router.push("/issues/edit/" + props.row.id);
        } else {
          swal({
            title: "Acesso Negado",
            text: "Você não tem permissão para editar esta tarefa.",
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    },
    generatePdf() {
      let params = new URLSearchParams();

      if (this.startDate) {
        params.append("start_date", this.startDate);
      }

      if (this.endDate) {
        params.append("end_date", this.endDate);
      }

      if (this.selectedProject) {
        params.append("project_id", this.selectedProject);
      }

      if (this.selectedUser) {
        params.append("user_id", this.selectedUser);
      }

      if (this.valueHour) {
        params.append("value_hour", this.valueHour);
      }

      this.fileUrl = this.$store.state.url + "issues/pdf?" + params.toString();
      window.open(this.fileUrl, "_blank");
    },
    filtered_ds(date) {
      this.created_at = date;
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    getProjects: function () {
      const self = this;
      const api = self.$store.state.api + "projects?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        summary: "summary",
        project_id: "project[name]",
        user_id: "user[name]",
        status: "status",
      };
      let filters = "&orderByDesc=id&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
  mounted: function () {
    const self = this;

    self.getUsers();
    self.getProjects();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>