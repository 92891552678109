<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-12" v-show="possui_fluxo">
                <div class="alert alert-warning" role="alert">
                  Atenção, não há fluxo de aprovação definido para esse centro de custo!
                </div>
              </div>
              <div :class="!rateio ? 'col-md-10' : 'd-none'">
                <label>Centro de Custo</label>
                <v-select
                v-model="dasp.bill_center_id"
                :options="billCenter"
                :reduce="(billCenter) => billCenter.id"
                :label="'number_name'"
                @input="searchBillCenter"
                ></v-select>
              </div>
              <div :class="rateio ? 'col-md-10' : 'd-none'">
                <label>Centro de Custo</label>
                <v-select
                  v-model="dasp.bill_center_id"
                  :options="billCenter"
                  :reduce="(billCenter) => billCenter.id"
                  :label="'number_name'"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-2">
                <br>
                <div class="custom-control custom-switch">
                  <input
                    autocomplete="new-password"
                    v-model="rateio"
                    placeholder=""
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch2"
                  />
                  <label class="custom-control-label" for="customSwitch2"
                    >Rateio</label
                  >
                </div>
              </div>
              <div class="col-md-4">
                <label>Valor Orçado</label>
                <input type="text" class="form-control" v-model="dasp.budget" v-money="moneyConfig">
              </div>
              <div class="col-md-4">
                <label>Valor Realizado</label>
                <input type="text" class="form-control" v-model="dasp.paid" v-money="moneyConfig">
              </div>
              <div class="col-md-4">
                <label>Urgência</label>
                <v-select
                  v-model="dasp.term"
                  :options="term"
                  :reduce="(term) => term.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Objeto da aquisição</label>
                <v-select
                  v-model="dasp.type"
                  :options="type"
                  :reduce="(type) => type.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Destino desta aquisição</label>
                <v-select
                  v-model="dasp.destination"
                  :options="destination"
                  :reduce="(destination) => destination.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Acordo com fornecedor</label>
                <v-select
                  v-model="dasp.agreement"
                  :options="agreement"
                  :reduce="(agreement) => agreement.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-12">
                <label>Colaborador</label>
                <v-select
                  v-model="dasp.collaborator"
                  :options="users"
                  :reduce="(users) => users.id"
                  :label="'name'"
                ></v-select>
              </div>
              <hr>
              <div class="col-12 mt-2">
                <h4>Detalhes do produto/serviço</h4>
              </div>
              <div class="col-md-5">
                <label>Descrição</label>
                <input type="text" class="form-control" v-model="products.description">
              </div>
              <div class="col-md-5">
                <label>Link</label>
                <input type="text" class="form-control" v-model="products.link">
              </div>
              <div class="col-md-2">
                <label>Quantidade</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  v-model="products.quantity"
                />
              </div>
              <div class="col-md-2">
                <button class="btn btn-add mt-2" @click="add">Adicionar</button>
              </div>
              <div class="col-md-12 mt-1">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 35%">Descrição</th>
                      <th scope="col" style="width: 30%">link</th>
                      <th scope="col" style="width: 15%">Quantidade</th>
                      <th scope="col" style="width: 10%">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in list" :key="index">
                      <td>{{ product.description }}</td>
                      <td>{{ product.link }}</td>
                      <td>{{ product.quantity }}</td>
                      <td>
                        <button
                          class="btn btn-crud delete"
                          @click="remover(index)"
                        >
                          <i
                            class="far fa-trash-alt"
                            data-toggle="tooltip"
                            title="Excluir"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/dasps" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Abrir Solicitação
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      rateio: 0,
      possui_fluxo: false,
      moneyConfig: {
        prefix: "R$ ",
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      dasp: {},
      billCenter: [],
      users: [],
      products: [],
      list: [],
      type: [
        {
          id: 1,
          name: 'Serviços',
        },
        {
          id: 2,
          name: 'Equipamentos e Materiais',
        },
      ],
      destination: [
        {
          id: 1,
          name: 'Patrimônio IT4D',
        },
        {
          id: 2,
          name: 'Para o cliente previsto no contrato de fornecimento',
        },
        {
          id: 3,
          name: 'Consumo',
        },
        {
          id: 4,
          name: 'Revenda',
        },
      ],
      term: [
        {
          id: 1,
          name: "Imediato",
        },
        {
          id: 2,
          name: "Muito urgente",
        },
        {
          id: 3,
          name: "Urgente",
        },
        {
          id: 4,
          name: "Não urgente",
        },
      ],
      agreement: [
        {
          id: 1,
          name: 'Apenas Pedido de Compra',
        },
        {
          id: 2,
          name: 'Fornecimento Recorrente',
        },
      ],
    };
  },
  computed: {},
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append(
        "bill_center_id",
        self.dasp.bill_center_id ? self.dasp.bill_center_id : ""
      );
      fd.append(
        "destination",
        self.dasp.destination ? self.dasp.destination : ""
      );
      fd.append("agreement", self.dasp.agreement ? self.dasp.agreement : "");
      fd.append(
        "collaborator",
        self.dasp.collaborator ? self.dasp.collaborator : ""
      );
      fd.append("list", JSON.stringify(self.list));

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "dasps";

      if (!self.list.length) {
        this.$message(null, "Você precisa adicionar produtos/serviços", "error");
        return;
      }

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          this.$router.push("/dasps");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDasp: function (id) {
      const self = this;
      const api = self.$store.state.api + "dasps/" + id;

      axios
        .get(api)
        .then((response) => {
          self.dasp = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBillCenter: function () {
      const self = this;
      const api = self.$store.state.api + "bill_centers?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.billCenter = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users_solicitation?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.users;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    formatarData(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    add() {

      if (this.products.description === "") {
        this.$message(null, "Você precisa informar a descrição", "error");
        return;
      }

      if (this.products.quantity === "") {
        this.$message(null, "Você precisa informar a quantidade", "error");
        return;
      }

      const newEntry = {
        description: this.products.description,
        link: this.products.link,
        quantity: this.products.quantity,
      };

      this.list.push(newEntry); // adiciona o novo objeto ao array de datas

      this.products.description = "";
      this.products.link = "";
      this.products.quantity = "";
    },
    remover(index) {
      const self = this;
      self.list.splice(index, 1);
    },
    searchBillCenter(){
      const self = this;
      let api = self.$store.state.api + `bill_center_flows?bill_center_id=${self.dasp.bill_center_id}`;
      self.$loading(true);

      axios
        .get(api)
        .then((response) => {          
          
          self.possui_fluxo = (response.data.data.length === 0) ? true : false;

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    }
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    
    self.getBillCenter();
    self.getUsers();

    if (id) {
      self.getDasp(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>