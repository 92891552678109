<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Solicitação de Reembolso</div>
              <div class="row">
                <a
                  v-if="
                    $store.state.user.perfil_id == 7 ||
                    $store.state.user.perfil_id == 8
                  "
                  class="btn btn-back mr-2"
                  :href="reimbursement_solicitation.url"
                  target="_blank"
                >
                  <i class="fas fa-file-pdf"></i> Exportar PDF
                </a>
                <button
                  data-toggle="modal"
                  data-target="#modalReceipt"
                  target="_blank"
                  class="btn btn-back mr-1"
                  v-if="
                    $store.state.user.perfil_id == 7 ||
                    ($store.state.user.perfil_id == 8 &&
                      reimbursement_solicitation.approve == 3)
                  "
                >
                  <i class="fas fa-receipt"></i> Anexar comprovante
                </button>
              </div>
              <div
                class="modal fade"
                id="modalReceipt"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalReceiptTitle"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Inserir comprovante
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row justify-content-center">
                        <div class="col-md-6">
                          <input
                            type="file"
                            id="receiptPic"
                            name="receiptPic"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        @click="saveReceipt"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="reimbursement_solicitation.approve == 1"
                class="col-md-12 mt-2 alert alert-success text-center"
                style="font-size: 20px; background-color: #4caf50; color: white"
                role="alert"
              >
                <strong>Solicitação paga!</strong>
              </div>
              <div
                v-if="reimbursement_solicitation.approve == 2"
                class="col-md-12 mt-2 alert alert-danger text-center"
                style="font-size: 20px; background-color: #ff0000; color: white"
                role="alert"
              >
                <strong>Esta solicitação foi rejeitada.</strong>
                <br /><br />
                <strong>Observação:</strong>
                {{
                  reimbursement_solicitation.observation
                    ? reimbursement_solicitation.observation
                    : "Sem observação"
                }}<br />
              </div>
              <div
                v-if="reimbursement_solicitation.approve == 3"
                class="col-md-12 mt-2 alert alert-warning text-center"
                style="font-size: 20px; color: white"
                role="alert"
              >
                <strong>Solicitação aguardando comprovante!</strong>
              </div>
            </div>

            <hr style="margin-top: -4px" />

            <div class="card-header" v-if="reimbursement_solicitation.receipt">
              <div class="card-title">Comprovante</div>
            </div>
            <table
              class="table table-bordered"
              style="width: 100%"
              v-if="reimbursement_solicitation.receipt"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 20%">Nome do Arquivo</th>
                  <th scope="col" style="width: 20%">baixar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ reimbursement_solicitation.receipt }}</td>
                  <td>
                    <a
                      class="btn btn-add mr-1"
                      :href="reimbursement_solicitation.receipt_url"
                      target="_blank"
                      v-if="reimbursement_solicitation.receipt"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <hr style="margin-top: 40px" />

            <div class="col-md-12">
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="Name"
                    ><span class="required">*</span> Nome do Colaborador:</label
                  >
                  <v-select
                    v-model="reimbursement_solicitation.user_id"
                    :options="users"
                    :reduce="(users) => users.id"
                    :label="'name'"
                    disabled
                  ></v-select>
                </div>
                <div class="form-group col-md-5">
                  <label>Valor do Adiantamento</label>
                  <input
                    :value="formattedAdvanceAmount"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="date"> Data da solicitação: </label>
                  <input
                    autocomplete="new-password"
                    class="form-control"
                    id="date"
                    placeholder=""
                    type="date"
                    v-model="reimbursement_solicitation.date"
                    disabled
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label><span class="required">*</span> Banco</label>
                  <input
                    v-model="reimbursement_solicitation.deposit_bank"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
                <div class="form-group col-md-4">
                  <label><span class="required">*</span> Âgencia</label>
                  <input
                    v-model="reimbursement_solicitation.branch"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
                <div class="form-group col-md-4">
                  <label>Conta Corrente</label>
                  <input
                    v-model="reimbursement_solicitation.checking_account"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <hr
                style="
                  margin-top: 50px;
                  width: 100%;
                  height: 1px;
                  background-color: #dddddd;
                "
              />

              <div class="row mt-1" v-if="reimbursements.length > 0">
                <div class="card-header">
                  <div class="card-title">Reembolso</div>
                </div>
                <div class="col-md-12">
                  <table class="table table-bordered" style="width: 100%">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 20%">Data da Despesa</th>
                        <th scope="col" style="width: 20%">Tipo da Despesa</th>

                        <th scope="col" style="width: 20%">
                          Descrição do Reembolso
                        </th>
                        <th scope="col" style="width: 20%">Valor</th>
                        <th scope="col" style="width: 20%">Arquivos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(reimbursement, index) in reimbursements"
                        :key="index"
                      >
                        <td>{{ reimbursement.date_reimbursement_f }}</td>
                        <td>
                          {{
                            getReimbursementTypeName(
                              reimbursement.reimbursement_type_id
                            )
                          }}
                        </td>
                        <td>{{ reimbursement.expense_description }}</td>
                        <td>{{ formatCurrency(reimbursement.amount) }}</td>
                        <td>
                          {{
                            reimbursement.file
                              ? reimbursement.file
                              : "Nenhum arquivo"
                          }}
                          <a
                            class="btn btn-add mr-1"
                            :href="reimbursement.file_url"
                            target="_blank"
                            v-if="reimbursement.file"
                          >
                            <i class="fas fa-download"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row" v-else>
                <div class="col-md-12">Nenhuma Despesa informada</div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    ><span class="required">*</span> Saldo a receber
                    (devolver)</label
                  >
                  <input
                    :value="
                      formatCurrency(
                        reimbursement_solicitation.balance_to_receive
                      )
                    "
                    type="text"
                    class="form-control"
                    v-money="moneyConfig"
                    disabled
                  />
                  <span
                    v-if="reimbursement_solicitation.balance_to_receive < 0"
                    class="text-danger"
                    >(Devolver a IT4D)</span
                  >
                  <span
                    v-else-if="
                      reimbursement_solicitation.balance_to_receive > 0
                    "
                    class="text-success"
                    >(Devolver ao Colaborador)</span
                  >
                </div>
                <div class="form-group col-md-6">
                  <label
                    ><span class="required">*</span> Total dos gastos</label
                  >
                  <input
                    :value="
                      formatCurrency(reimbursement_solicitation.total_expenses)
                    "
                    type="text"
                    class="form-control"
                    v-money="moneyConfig"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row mt-3 mb-1"
                v-if="
                  reimbursement_solicitation.approve === 0 &&
                  $store.state.user.perfil_id == 8
                "
              >
                <div class="col-md-6">
                  <button class="btn btn-success btn-block" @click="approved">
                    <i class="fas fa-check"></i> Pagar
                  </button>
                </div>
                <div
                  class="col-md-6"
                  v-if="
                    reimbursement_solicitation.approve === 0 &&
                    $store.state.user.perfil_id == 8
                  "
                >
                  <button
                    class="btn btn-danger btn-block"
                    data-toggle="modal"
                    data-target="#modalRejeitar"
                  >
                    <i class="fas fa-ban"></i> Rejeitar
                  </button>
                </div>
              </div>
              <!-- Modal Rejeitar -->
              <div
                class="modal fade"
                id="modalRejeitar"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalRejeitarTitle"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Rejeitar Solicitação
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <label>Observação</label>
                          <textarea
                            class="form-control"
                            cols="30"
                            rows="10"
                            v-model="reimbursement_solicitation.observation"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="reject"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import Money from "v-money";

export default {
  name: "imageUpload",
  data() {
    return {
      moneyConfig: {
        prefix: "R$ ",
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      reimbursement_solicitation: {
        collaborator_name: "",
        date: "",
        checking_account: "",
        balance_to_receive: 0,
        branch: "",
        deposit_bank: "",
        country: "",
        status: "",
        bill_center_id: "",
        total_expenses: 0,
        attachments: [],
        observation: "",
      },
      reimbursements: [],
      reimbursement_types: [],
      expense_description: "",
      reimbursement_solicitation_id: "",
      reimbursement_type_id: "",
      amount: "",
      advance_amount: "",
      bill_centers: [],
      users: [],
    };
  },
  computed: {
    formattedAdvanceAmount() {
      const reimbursement = this.reimbursements[0];

      if (reimbursement && reimbursement.hasOwnProperty("advance_amount")) {
        const advanceAmount = parseFloat(reimbursement.advance_amount);

        if (!isNaN(advanceAmount)) {
          return `R$ ${advanceAmount.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
      }
      return "";
    },
  },
  methods: {
    mFDReceipt: function () {
      const self = this;
      let fd = new FormData();
      let anexoRas = document.getElementById("receiptPic");
      fd.append("id", self.reimbursement_solicitation.id);
      fd.append("receipt", anexoRas.files[0] ? anexoRas.files[0] : "");
      fd.append("approve", self.reimbursement_solicitation.approve);
      anexoRas.value = "";
      return fd;
    },
    saveReceipt: function () {
      const self = this;
      let api =
        self.$store.state.api + "reimbursement_solicitations/save_receipt";
      let fd = self.mFDReceipt();

      self.$loading(true);
      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getReimbursementSolicitations(
            self.reimbursement_solicitation.id
          );
          self.receipt = "";
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    approved() {
      const self = this;
      swal({
        title: "Deseja Pagar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, Pagar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi pago!", "info");
            break;

          case "confirm":
            let api =
              self.$store.state.api + "reimbursement_solicitations/approved/";

            self.$loading(true);
            axios
              .post(api, {
                id: self.reimbursement_solicitation.id,
              })
              .then((response) => {
                self.$router.push("/reimbursement_solicitations");
                swal("Sucesso", "Registro pago com sucesso!", "success");
                self.$loading(false);
              });
            break;
        }
      });
    },
    reject() {
      const self = this;
      if (!self.reimbursement_solicitation.observation) {
        swal("Aviso", "Por favor, preencha o campo de observação!", "warning");
        return;
      }
      swal({
        title: "Deseja rejeitar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, rejeitar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi rejeitado!", "info");
            break;

          case "confirm":
            let api =
              self.$store.state.api + "reimbursement_solicitations/reject/";
            self.$loading(true);
            axios
              .post(api, {
                id: self.reimbursement_solicitation.id,
                observation: self.reimbursement_solicitation.observation,
              })
              .then((response) => {
                $("#modalRejeitar").modal("hide");
                self.$router.push("/reimbursement_solicitations");
                swal("Sucesso", "Registro rejeitado com sucesso!", "success");
                self.$loading(false);
              });
            break;
        }
      });
    },
    getReimbursementTypeName(reimbursementTypeId) {
      const reimbursementType = this.reimbursement_types.find(
        (type) => type.id === reimbursementTypeId
      );

      return reimbursementType ? reimbursementType.name : "Tipo não encontrado";
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },

    convertCurrencyToNumber(currencyString) {
      const numericString = currencyString.replace(/[^\d.,]/g, "");

      const numberWithDot = numericString.replace(",", ".");

      const numberValue = parseFloat(numberWithDot);

      return isNaN(numberValue) ? 0 : numberValue;
    },

    incluirReembolso() {
      if (this.reimbursement_type_id === "") {
        this.$message(null, "O tipo da despesa é obrigatório", "error");
        return;
      }

      if (this.expense_description === "") {
        this.$message(null, "A descrição da despesa é obrigatória", "error");
        return;
      }

      const amountNumber = this.convertCurrencyToNumber(this.amount);
      const advanceAmountNumber = this.convertCurrencyToNumber(
        this.advance_amount
      );

      const newReimbursement = {
        reimbursement_type_id: this.reimbursement_type_id,
        expense_description: this.expense_description,
        amount: amountNumber,
        advance_amount: advanceAmountNumber,
        created_at: new Date(),
      };

      this.reimbursements.push(newReimbursement);

      this.expense_description = "";
      this.reimbursement_type_id = "";
      this.amount = "";
      this.advance_amount = "";

      this.calculateTotalExpenses();
      this.calculateBalanceToReceive();
    },

    calculateTotalExpenses() {
      let totalExpenses = this.reimbursements.reduce((total, reimbursement) => {
        const amount = parseFloat(reimbursement.amount);
        return isNaN(amount) ? total : total + amount;
      }, 0);

      this.reimbursement_solicitation.total_expenses = totalExpenses;
    },

    calculateBalanceToReceive() {
      let balanceToReceive = this.reimbursements.reduce(
        (total, reimbursement) => {
          const amount = parseFloat(reimbursement.amount);
          return isNaN(amount) ? total : total + amount;
        },
        0
      );

      let advanceAmount = this.reimbursements.reduce((total, reimbursement) => {
        const advance = parseFloat(reimbursement.advance_amount);
        return isNaN(advance) ? total : total + advance;
      }, 0);

      balanceToReceive -= advanceAmount;

      this.reimbursement_solicitation.balance_to_receive = balanceToReceive;
      this.calculateTotalExpenses();
    },

    save: function () {
      const self = this;
      let api = self.$store.state.api + "reimbursement_solicitations";

      if (self.reimbursement_solicitation.id) {
        api += "/" + self.reimbursement_solicitation.id;
        self.reimbursement_solicitation._method = "PUT";
      }

      const payload = {
        reimbursement_solicitation: self.reimbursement_solicitation,
        reimbursements: self.reimbursements,
      };

      axios
        .post(api, payload)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/reimbursement_solicitations");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getReimbursementSolicitations: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "reimbursement_solicitations/" +
        id +
        "?with[]=attachments&with[]=reimbursements&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.reimbursement_solicitation = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getReimbursementTypes: function () {
      const self = this;
      const api = self.$store.state.api + "reimbursement_types/";

      axios
        .get(api)
        .then((response) => {
          self.reimbursement_types = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getReimbursements: function (solicitation_id) {
      const self = this;

      let api =
        self.$store.state.api +
        `reimbursement_solicitations/${solicitation_id}/reimbursements`;

      axios
        .get(api)
        .then((response) => {
          self.reimbursements = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getBillCenters: function () {
      const self = this;
      const api = self.$store.state.api + "bill_centers/";

      axios
        .get(api)
        .then((response) => {
          self.bill_centers = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users/";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getReimbursementTypes();
    self.getBillCenters();
    self.getUsers();

    if (id) {
      self.getReimbursementSolicitations(id);
      self.getReimbursements(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
    Money,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
</style>