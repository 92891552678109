import Crud from '../views/Task/Crud'
import Entity from '../views/Task/Add'
import Entity2 from '../views/Task/Task'

let routes = {
    path: '/issues',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/issues/add',
            name: 'NewTask',
            component: Entity,
            meta: {
                pageTitle: 'Tasks',
                breadcrumb: [
                    {
                        text: 'Tasks',
                        active: false,
                        to: '/tasks',
                    },
                    {
                        text: 'Adicionar Task',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/issues/edit/:id?',
            name: 'EditTask',
            component: Entity,
            meta: {
                pageTitle: 'Tasks',
                breadcrumb: [
                    {
                        text: 'Tasks',
                        active: false,
                        to: '/issues',
                    },
                    {
                        text: 'Editar Task',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/issues/ticket/:id?',
            name: 'attendTask',
            component: Entity2,
            meta: {
                pageTitle: 'Tasks',
                breadcrumb: [
                    {
                        text: 'Tasks',
                        active: false,
                        to: '/issues',
                    },
                    {
                        text: 'Atender Task',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/tasks',
            name: 'list-tasks',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Tasks',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
