import Crud from '../views/TypeCall/Crud'
import Entity from '../views/TypeCall/Add'


let routes = {
    path: '/type_calls',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/type_calls/add',
            name: 'NewTypeCall',
            component: Entity,
            meta: {
                pageTitle: 'Tipos de chamado',
                breadcrumb: [
                    {
                        text: 'Tipos de chamado',
                        active: false,
                        to: '/type_calls',
                    },
                    {
                        text: 'Adicionar tipo de chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/type_calls/edit/:id?',
            name: 'EditTypeCall',
            component: Entity,
            meta: {
                pageTitle: 'Tipos de chamado',
                breadcrumb: [
                    {
                        text: 'Tipos de chamado',
                        active: false,
                        to: '/type_calls',
                    },
                    {
                        text: 'Editar tipo de chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/type_calls',
            name: 'list-type-calls',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Tipos de chamado',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
