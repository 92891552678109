<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Adicionar Usuário</div>
            </div>
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action />
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Nome:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="users.name"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="Email">
                        <span class="required">*</span> Email:
                      </label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id="Email"
                        placeholder=""
                        type="text"
                        v-model="users.email"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Cellphone">Cliente:</label>
                      <v-select
                        v-model="users.client_id"
                        :options="clients"
                        :reduce="(clients) => clients.id"
                        :label="'company_name'"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Celular:</label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id="Cellphone"
                        type="text"
                        v-mask="'(99) 99999-9999'"
                        v-model="users.cellphone"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Phone">Telefone:</label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        class="form-control"
                        id="Phone"
                        v-model="users.telphone"
                        v-mask="'(99) 9999-9999'"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="inputUser">
                        <span class="required">*</span> Nível de acesso:
                      </label>
                      <select v-model="users.access_nivel" class="form-control">
                        <option :value="1" :key="1">Usuário Padrão</option>
                        <option :value="11" :key="11">Terceiro</option>
                        <option
                          v-if="
                            $store.state.user.perfil_id == 7 ||
                            $store.state.user.perfil_id == 8
                          "
                          :value="7"
                          :key="7"
                        >
                          Administrador RH
                        </option>
                        <option
                          v-if="
                            $store.state.user.perfil_id == 7 ||
                            $store.state.user.perfil_id == 8
                          "
                          :value="8"
                          :key="8"
                        >
                          Administrador Geral
                        </option>
                        <option :value="10" :key="10">Usuário Cliente</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputUser">
                        <span class="required">*</span> Obrigatório
                        preenchimento de escala:
                      </label>
                      <select v-model="users.home_office" class="form-control">
                        <option :value="0">Não</option>
                        <option :value="1">Sim</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputUser">
                        <span class="required">*</span> Modera posts:
                      </label>
                      <select v-model="users.mod" class="form-control">
                        <option :value="0">Não</option>
                        <option :value="1">Sim</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputUser">
                        <span class="required">*</span> Faz daily:
                      </label>
                      <select v-model="users.daily" class="form-control">
                        <option :value="0">Não</option>
                        <option :value="1">Sim</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputUser">
                        <span class="required">*</span> Notificar:
                      </label>
                      <select v-model="users.notify" class="form-control">
                        <option :value="false">Não</option>
                        <option :value="true">Sim</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="Cellphone">Nome Jira:</label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id=""
                        type="text"
                        v-model="users.name_jira"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="row" v-if="$route.params.id">
                    <div class="col-md-12 mb-2">
                      <button
                        type="button"
                        class="btn btn-add"
                        @click="toggleFormSenha"
                      >
                        <i class="fas fa-key"></i> Alterar Senha
                      </button>
                    </div>
                  </div>
                  <div class="form-row" v-if="change_password">
                    <div class="form-group col-md-6">
                      <label for="password">
                        <span class="required">*</span> Senha:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="password"
                        id="password"
                        class="form-control"
                        v-model="users.password"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="confirmPassword">
                        <span class="required">*</span> Confirme a senha:
                      </label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id="confirmPassword"
                        placeholder=""
                        type="password"
                        v-model="users.password_confirmation"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 mt-1 row">
                    <div class="col-md-12 text-center">
                      <h4>Desenhe sua assinatura abaixo</h4>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center">
                      <canvas
                        id="canvas"
                        ref="canvas"
                        style="border: 1px solid #000"
                        width="700"
                        height="200"
                        @mousedown="startDrawing"
                        @mousemove="draw"
                        @mouseup="stopDrawing"
                        @mouseleave="stopDrawing"
                      ></canvas>
                    </div>
                    <div class="col-md-12 mt-1 d-flex justify-content-center">
                      <button class="btn btn-delete" @click="clearSignature">
                        Limpar Assinatura
                      </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="userPic"> Foto do Perfil </label>
                    <input type="file" class="form-control" id="userPic" />
                    <div class="user-img">
                      <img
                        v-if="previewImage"
                        :src="previewImage"
                        class="photo"
                      />
                    </div>
                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/users" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add ml-1" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      departments: [],
      change_password: true,
      users: {
        name: "",
        email: "",
        cellphone: "",
        telphone: "",
        photo: "",
        access_nivel: "",
        password: "",
        password_confirmation: "",
        notify: "",
        client_id: "",
        home_office: "",
        name_jira: "",
      },
      clients: [],
      departments: {},

      errors: undefined,

      previewImage: null,
    };
  },
  computed: {},
  methods: {
    toggleFormSenha() {
      const self = this;
      if (self.change_password) {
        self.change_password = false;
      } else {
        self.change_password = true;
      }
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let photo = document.getElementById("userPic");

      fd.append("name", self.users.name);
      fd.append("email", self.users.email);
      fd.append("cellphone", self.users.cellphone);
      fd.append("access_nivel", self.users.access_nivel);
      fd.append("home_office", self.users.home_office);
      fd.append("client_id", self.users.client_id ? self.users.client_id : "");
      fd.append("password", self.users.password);
      fd.append("password_confirmation", self.users.password_confirmation);
      fd.append("accept_terms", 0);
      fd.append("calendar_color", self.users.calendar_color);
      fd.append("notify", self.users.notify);
      fd.append("daily", self.users.daily);
      fd.append("mod", self.users.mod);
      fd.append("nome_jira", self.users.nome_jira);
      //fd.append(
      //  "signature",
      //  self.signature.toDataURL() ? self.signature.toDataURL() : false
      //);

      fd.append("_method", "POST");

      fd.append("photo", photo.files[0] ? photo.files[0] : "");

      if (self.users.id) {
        fd.append("id", self.users.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "users";

      let fd = self.makeFormData();

      if (self.users.id) {
        api += "/" + self.users.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/users");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.users.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.users.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.users.street = self.endereco.logradouro;
            self.users.neighborhood = self.endereco.bairro;
            self.users.state = self.endereco.uf;
            self.users.city = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + "users/" + id;

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data[0];
          self.users.password = "";
          self.change_password = false;

          self.getCanvas();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getClients: function () {
      const self = this;
      const api = self.$store.state.api + "clients/";

      axios
        .get(api)
        .then((response) => {
          self.clients = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // getCompanies: function (id) {
    //   const self = this;
    //   const api = self.$store.state.api + "companies/?paginated=false";

    //   axios
    //     .get(api)
    //     .then((response) => {
    //       self.companies = response.data.data;
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
    getCanvas: function () {
      const self = this;

      self.ctx.clearRect(0, 0, 700, 200);

      const image = new Image();
      image.src = this.users.signature;

      console.log(this.ctx);

      console.log(image);

      image.onload = function () {
        console.log("limpo e onload");
        self.ctx.drawImage(image, 0, 0, 700, 200);
      };
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    startDrawing(event) {
      this.isDrawing = true;
      const { offsetX, offsetY } = event;
      this.lastX = offsetX;
      this.lastY = offsetY;
    },
    draw(event) {
      if (!this.isDrawing) return;
      const { offsetX, offsetY } = event;
      this.ctx.beginPath();
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.strokeStyle = "#000";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
      this.lastX = offsetX;
      this.lastY = offsetY;
      this.drawingData.push({ x: offsetX, y: offsetY });
    },
    stopDrawing() {
      this.isDrawing = false;
      this.signature = document.getElementById("canvas");
    },
    clearSignature() {
      this.saved = false;
      this.ctx.clearRect(0, 0, 700, 200);
      this.drawingData = [];
    },
  },
  mounted: function () {
    const self = this;

    self.getClients();
    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getUsers(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>