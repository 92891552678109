<template>
  <div class="main-content">
    <div class="row mb-3">
      <div class="col-md-3">
        <label>Data de Ínicio</label>
        <input type="date" class="form-control" v-model="filters.data_inicio" />
      </div>
      <div class="col-md-3">
        <label>Data do Fim</label>
        <input type="date" class="form-control" v-model="filters.data_fim" />
      </div>
      <div class="col-md-12 mt-2 text-end">
        <button type="button" class="btn btn-primary" @click="filterData">
          Filtrar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" style="height: 585px">
        <div class="card p-1 pb-2" style="height: 100%">
          <div class="row">
            <div class="col-md-12 text-align-center">
              <h2
                class="text-center mb-4"
                v-if="filters.data_inicio != '' && filters.data_fim != ''"
              >
                Gráfico de fechamento de cards de
                {{ filters.data_inicio }} a
                {{ filters.data_fim }}
              </h2>
              <h2 class="text-center mb-4" v-else>
                Gráfico de fechamento de cards da semana
              </h2>
              <div class="container text-align-center">
                <div class="row">
                  <div v-if="loading" class="loader">Carregando...</div>
                  <div v-else class="chart-container">
                    <BarChart
                      :chartData="chartDataFromAPI"
                      :chartOptions="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card p-1 pb-2">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Quantidade de card de {{ filters.data_inicio }} a
            {{ filters.data_fim }}
          </h2>
          <h2 class="text-center mb-4" v-else>Quantidade de cards</h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <!-- <th>Posição</th> -->
                  <th>Usuário</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in cardsFinalizadosPorUsuario"
                  :key="index"
                >
                  <!-- <td>
                            <template v-if="index === 0">
                                <i class="fas fa-trophy gold-icon"></i>
                            </template>
                            <template v-else-if="index === 1">
                                <i class="fas fa-star silver-icon"></i>
                            </template>
                            <template v-else-if="index === 2">
                                <i class="fas fa-star bronze-icon"></i>
                            </template>
                        </td> -->
                  <td>{{ item.user_name }}</td>
                  <td>{{ item.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-1 pb-2">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Cards Finalizados por Projeto de {{ filters.data_inicio }} a
            {{ filters.data_fim }}
          </h2>
          <h2 class="text-center mb-4" v-else>Cards Finalizados por Projeto</h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Projeto</th>
                  <th>Cards Finalizados</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in cardsFinalizadosPorProjeto"
                  :key="index"
                >
                  <td>{{ item.project_name }}</td>
                  <td>{{ item.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-1 pb-2 mt-4">
          <h2
            class="text-center mb-4"
            v-if="filters.data_inicio != '' && filters.data_fim != ''"
          >
            Cards Finalizados na Semana de {{ filters.data_inicio }} a
            {{ filters.data_fim }}
          </h2>
          <h2 class="text-center mb-4" v-else>Cards Finalizados na Semana</h2>
          <div class="row">
            <div class="col-md-3 mb-2">
              <label>Dev</label>
              <input
                type="text"
                class="form-control"
                v-model="filters.assignee"
              />
            </div>
            <div class="col-md-3 mt-2">
              <button
                type="button"
                class="btn btn-primary"
                @click="getCardsWeekDone"
              >
                Filtrar
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Dev Alocado</th>
                  <th>Título do Card</th>
                  <th>Projeto</th>
                  <th>Data Fim</th>
                  <th>Tempo médio de conclusão</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in issues" :key="index">
                  <td>{{ item.user ? item.user.name : "" }}</td>
                  <td>{{ item.summary }}</td>
                  <td>{{ item.project ? item.project.name : "" }}</td>
                  <td>{{ item.due_date_f }}</td>
                  <td>{{ item.timeSpent }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
            >
              <pagination
                :records="total"
                :options="options"
                :per-page="10"
                @paginate="getCardsWeekDone"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-1 pb-2 mt-4">
          <h2 class="text-center mb-4">Status dos Sistemas</h2>
          <div class="table-responsive">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th style="width: 15%">Projeto</th>
                  <th style="width: 15%">Status</th>
                  <th style="width: 15%">Descrição</th>
                  <th>Ínicio do Projeto</th>
                  <th>Fim do Projeto</th>
                  <th style="width: 20%">Feito</th>
                  <th>Fazendo</th>
                  <th>A Fazer</th>
                  <th style="width: 30%">Devs</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(issue, index) in issues_graphic" :key="index">
                  <td>{{ issue.project_name }}</td>
                  <td v-html="getStatusBadge(issue.status_project_id)"></td>
                  <td>{{ issue.description ? issue.description : "" }}</td>
                  <td>{{ issue.start_date_f }}</td>
                  <td>
                    {{ issue.end_date_f ? issue.end_date_f : "" }}
                  </td>
                  <td>
                    <div
                      class="progress"
                      style="height: 20px; position: relative"
                    >
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        :style="
                          'width:' +
                          issue.tasks.done_percentage +
                          '%; height: 20px;'
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="mt-2">
                      <span class="text-dark">
                        {{ issue.tasks.done }} Feito ({{
                          issue.tasks.done_percentage
                        }}%)
                      </span>
                    </div>
                  </td>
                  <td>
                    <a @click="getCardsDoing(issue.project_id)">
                      {{ issue.tasks.doing }} ({{
                        issue.tasks.doing_percentage
                      }}%)
                    </a>
                  </td>
                  <td>
                    <a @click="getCardsTodo(issue.project_id)">
                      {{ issue.tasks.todo }} ({{
                        issue.tasks.todo_percentage
                      }}%)
                    </a>
                  </td>
                  <td>
                    <span v-if="issue.users && issue.users.length > 0">
                      <strong
                        >{{ issue.users.map((user) => user.name).join(", ") }}
                      </strong>
                    </span>
                    <span v-else> Nenhum Dev com card em aberto </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Modal Todo -->
          <div
            class="modal fade"
            id="modalTodo"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalTodoTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <div class="table-responsive">
                    <table class="table table-striped text-center">
                      <thead>
                        <tr>
                          <th>Projeto</th>
                          <th>Título</th>
                          <th>Responsável</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="todo in selectedProjectTasks" :key="todo.id">
                          <td>{{ todo.project_name }}</td>
                          <td>{{ todo.summary }}</td>
                          <td>{{ todo ? todo.assignee : "" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row justify-content-center">
                    <div class="col-md-12"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Doing -->
          <div
            class="modal fade"
            id="modalDoing"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalDoingTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <div class="table-responsive">
                    <table class="table table-striped text-center">
                      <thead>
                        <tr>
                          <th>Projeto</th>
                          <th>Título</th>
                          <th>Responsável</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="doing in selectedProjectTasksDoing"
                          :key="doing.id"
                        >
                          <td>{{ doing.project_name }}</td>
                          <td>{{ doing.summary }}</td>
                          <td>{{ doing ? doing.assignee : "" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row justify-content-center">
                    <div class="col-md-12"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "@/components/BarChart.vue";
import { Pagination } from "vue-pagination-2";

export default {
  components: {
    BarChart,
    Pagination,
  },
  data() {
    return {
      filters: { data_inicio: "", data_fim: "", assignee: "" },
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      pages: [],
      issues: [],
      issues_graphic: [],
      issue: {
        name: "Carregando...",
        tasks: {
          impeded: "0",
          todo: "0",
          doing: "0",
          done: "0",
        },
      },
      chartDataFromAPI: {
        labels: [],
        datasets: [
          {
            label: "Cards Finalizados",
            backgroundColor: "#4F81BD",
            data: [],
            dates: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const label = context.dataset.label || "";
                const value = context.raw;
                const date = context.dataset.dates[context.dataIndex];
                return `${label}: ${value} em ${date}`;
              },
            },
          },
        },
      },
      loading: true,
      cardsFinalizadosPorUsuario: [],
      cardsFinalizadosPorProjeto: [],
      data: [],
      cards_todo: [],
      cards_doing: [],
      selectedProjectTasks: [],
      selectedProjectTasksDoing: [],
    };
  },
  methods: {
    getStatusBadge(statusProjectId) {
      switch (statusProjectId) {
        case 1:
          return '<span class="badge" style="background-color: red;">Em Desenvolvimento</span>';
        case 2:
          return '<span class="badge" style="background-color: orange;">Em Homologação</span>';
        case 3:
          return '<span class="badge" style="background-color: green;">Em Operação</span>';
        case 4:
          return '<span class="badge" style="background-color: gray;">Aguardando definir prioridade</span>';
        default:
          return '<span class="badge" style="background-color: black;">Desconhecido</span>';
      }
    },
    getIssues() {
      const self = this;
      let api = self.$store.state.api + "issues_percentage";

      axios
        .get(api)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            self.issues_graphic = data;
          } else {
            self.issues_graphic = [
              {
                project_name: "Não há Sistemas cadastrados",
                tasks: {
                  done: 0,
                  todo: 0,
                  doing: 0,
                  impeded: 0,
                },
              },
            ];
          }
        })
        .catch((error) => {
          self.issues_graphic = [
            {
              project_name: "Falha ao conectar, tentando novamente...",
              tasks: {
                done: 0,
                todo: 0,
                doing: 0,
                impeded: 0,
              },
            },
          ];
        });
    },

    getGraphic() {
      const api = this.$store.state.api + "graphics";

      const params = {
        data_inicio: this.filters.data_inicio,
        data_fim: this.filters.data_fim
          ? this.filters.data_fim + " 23:59:59"
          : null,
      };

      axios
        .get(api, { params })
        .then((response) => {
          const responseData = response.data;
          this.chartDataFromAPI.labels =
            responseData.cardsFinalizadosPorUsuario.map(
              (item) => item.user_name
            );
          this.chartDataFromAPI.datasets[0].data =
            responseData.cardsFinalizadosPorUsuario.map((item) => item.count);
          this.chartDataFromAPI.datasets[0].dates =
            responseData.cardsFinalizadosPorUsuario.map(
              (item) => item.due_date
            );

          this.cardsFinalizadosPorUsuario =
            responseData.cardsFinalizadosPorUsuario;
          this.cardsFinalizadosPorProjeto =
            responseData.cardsFinalizadosPorProjeto;

          this.data = responseData.data;

          this.loading = false;
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
          this.loading = false;
        });
    },
    getCardsWeekDone: function (page) {
      const self = this;

      const params = {
        data_inicio: self.filters.data_inicio,
        data_fim: self.filters.data_fim,
        assignee: self.filters.assignee,
      };

      self.page = page || 1;

      let api = self.$store.state.api + "cardsWeekDone?page=" + self.page;

      self.$loading(true);
      axios
        .get(api, { params })
        .then((response) => {
          self.issues = response.data.data;
          self.total = response.data.total;

          self.issues.forEach((issue) => {
            const createdAt = new Date(issue.created_at);
            const dueDate = new Date(issue.due_date);
            const timeDiff = (dueDate - createdAt) / (1000 * 60);

            const hours = Math.floor(timeDiff / 60);
            const minutes = Math.round(timeDiff % 60);
            issue.timeSpent = `${hours}h ${minutes}m`;
          });
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getCardsTodo: function (projectId) {
      const self = this;
      let api = self.$store.state.api + `cardsTodo?project_id=${projectId}`;

      self.$loading(true);
      axios
        .get(api)
        .then((response) => {
          self.selectedProjectTasks = response.data.data;
          self.$loading(false);
          $("#modalTodo").modal("show");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getCardsDoing: function (projectId) {
      const self = this;
      let api = self.$store.state.api + `cardsDoing?project_id=${projectId}`;

      self.$loading(true);
      axios
        .get(api)
        .then((response) => {
          self.selectedProjectTasksDoing = response.data.data;
          self.$loading(false);
          $("#modalDoing").modal("show");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    filterData() {
      this.getGraphic();
      this.getCardsWeekDone();
    },
  },
  mounted() {
    this.getGraphic();
    this.getCardsWeekDone();
    this.getIssues();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.main-content {
  margin-top: 20px; /* Ajuste conforme necessário */
}

.container {
  width: 100%;
}

.chart-container {
  width: 100%; /* Utilize 100% para ocupar a largura completa da coluna */
}

.loader {
  font-size: 3vh;
  font-weight: 700;
  text-align: center;
  padding: 2em;
}

.gold-icon {
  color: gold;
}

.silver-icon {
  color: silver;
}

.bronze-icon {
  color: #cd7f32;
}
</style>
