import Crud from '../views/ReimbursementType/Crud'
import Entity from '../views/ReimbursementType/Add'

let routes = {
    path: '/reimbursement_types',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/reimbursement_types/add',
            name: 'NewReimbursementType',
            component: Entity,
            meta: {
                pageTitle: 'Tipo de Despesa',
                breadcrumb: [
                    {
                        text: 'Tipo de Despesa',
                        active: false,
                        to: '/reimbursement_types',
                    },
                    {
                        text: 'Adicionar Tipo de Despesa',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reimbursement_types/edit/:id?',
            name: 'EditReimbursementType',
            component: Entity,
            meta: {
                pageTitle: 'Tipo de Despesa',
                breadcrumb: [
                    {
                        text: 'Tipo de Despesa',
                        active: false,
                        to: '/reimbursement_types',
                    },
                    {
                        text: 'Editar Tipo de Despesa',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reimbursement_types',
            name: 'list-reimbursement-types',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Tipo de Despesa',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
