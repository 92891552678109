import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import env from '../config/env';
import Router from '../router/'

Vue.use(Vuex)

const baseURL = env.baseURL;
const normalURL = env.normalURL;
const downloadURL = env.downloadURL;

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  state: {
    user: {
      name: '',
      photo: '',
      company_logo: '',
      company_name: '',
      job: '',
      email: '',
      is_admin: '',
      password_expired: 0,
      mod: 0,
      perfil_id: 0,
      id: 0,
      systemVersion: '',
    },
    authenticated: false,
    notifications: 0,
    token: '',
    api: baseURL,
    url: normalURL,
    export: downloadURL,
    breadcrumbs: [{ name: "Home", route: "/" }]
  },
  getters: {
    token: state => {
      return state.token;
    },
    authenticated: state => {
      if (state.token == null || state.token == 'null') {
        return false;
      }

      return state.authenticated;
    },
    logo: state => {
      return state.logo;
    },
    user: state => {
      return state.user;
    },
    api: state => {
      return state.api;
    },
    breadcrumbs: state => {
      return state.breadcrumbs;
    }
  },
  mutations: {
    auth(state, token) {

      if (token != null && token != undefined) {
        state.authenticated = true;
        state.token = token;

        localStorage.clear();
        localStorage.setItem('token', token);

        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');
        this.commit('profileUpdate');
      }
    },
    updateSystemVersion(state, version) {
      state.systemVersion = version;
    },
    updateNotifications(state, data) {
      state.notifications = Number(state.notifications) + 1;
    },
    profileUpdate(state) {
      axios.post(state.api + 'me', {
        token: state.token
      })
        .then(response => {
          state.user.id = response.data.id;
          state.user.name = response.data.name;
          state.user.email = response.data.email;
          state.user.photo = state.url + "user/image/" + response.data.id;
          state.user.company_logo = state.url + "companies/logo/" + response.data.company_id;
          state.user.company_name = response.data.company_name;
          state.user.cellphone = response.data.cellphone;
          state.user.perfil_id = response.data.access_nivel;
          state.user.password_expired = response.data.password_expired;
          state.user.mod = response.data.mod;


          localStorage.setItem('accept_terms', response.data.accept_terms);
          localStorage.setItem('perfil_id', response.data.access_nivel);

          axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');

          if (response.data.access_nivel == 10) {
            Router.push('/calls');
          }

        })
        .catch(error => {
          localStorage.clear();

          Router.push('/login');
        });
    },
    updateBreadCrumbs(state, newRoute) {
      if (state.breadcrumbs.length > 1) {
        state.breadcrumbs.pop();
      }
      state.breadcrumbs.push(newRoute)
      // state.breadcrumbs = newRoute;
    }
  },
  actions: {
    initialize(context) {
      const token = localStorage.getItem('token');
      context.commit('auth', token);
    },
    fetchSystemVersion(context) {
      // Lógica para buscar a versão do sistema da sua API
      const api = `${context.state.api}system/version`;
      axios.get(api)
        .then(response => {
          context.commit('updateSystemVersion', response.data.version);
        })
        .catch(error => {
          console.error('Erro ao buscar a versão do sistema:', error);
        });
    },
  },
  mounted() {
    this.$store.dispatch('fetchSystemVersion');
  },
})
