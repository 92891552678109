import Index from '../views/GraphicTicket/Index'

let routes = {
    path: '/graphic_tickets',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/graphic_tickets',
            name: 'list-graphic-tickets',
            component: Index,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Gráfico de chamados finalizados por projeto',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
