import Vue from 'vue'
import VueRouter from 'vue-router'

import BillCenter from './bill_centers'
import Call from './calls'
import Client from './clients'
import ClientContact from './client_contacts'
import Daily from './dailies'
import Dasp from './dasps'
import Graphic from './graphic'
import GraphicTicket from './graphic_ticket'
import Group from './groups'
import HomeOffice from './home_offices'
import KnowledgeBase from './knowledge_base'
import Projects from './projects'
import ProjectType from './project_types'
import ReasonsDelete from './reasons_delete'
import ReimbursementSolicitation from './reimbursement_solicitations'
import ReimbursementType from './reimbursement_types'
import StatusProject from './status_project'
import Task from './tasks'
import Team from './team'
import TypeCall from './type_calls'
import User from './user'
import Version from './version'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    BillCenter,
    Call,
    Client,
    ClientContact,
    Daily,
    Dasp,
    Graphic,
    GraphicTicket,
    Group,
    HomeOffice,
    KnowledgeBase,
    Projects,
    ProjectType,
    ReasonsDelete,
    ReimbursementSolicitation,
    ReimbursementType,
    StatusProject,
    Task,
    Team,
    TypeCall,
    User,
    Version,
    {
      path: '/',
      name: 'Posts',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard-tv',
      name: 'dashboard-tv',
      component: () => import('@/views/DashTV.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const perfil_id = localStorage.getItem('perfil_id');
  const terms = localStorage.getItem('accept_terms');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  if (to.meta.perfil_id) {
    if (!to.meta.perfil_id.includes(perfil_id)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
