import Crud from '../views/Call/Crud'
import Entity from '../views/Call/Add'
import Entity2 from '../views/Call/Ticket'
import Crud1 from '../views/Call/CallFinishedCancel'
import Crud2 from '../views/Call/MyCall'
import View from '../views/Call/View'

let routes = {
    path: '/calls',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/calls/add',
            name: 'NewCall',
            component: Entity,
            meta: {
                pageTitle: 'Chamados',
                breadcrumb: [
                    {
                        text: 'Chamados',
                        active: false,
                        to: '/calls',
                    },
                    {
                        text: 'Adicionar chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/calls/ticket/:id?',
            name: 'attendCall',
            component: Entity2,
            meta: {
                pageTitle: 'Chamados',
                breadcrumb: [
                    {
                        text: 'Chamados',
                        active: false,
                        to: '/calls',
                    },
                    {
                        text: 'Atender chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/calls',
            name: 'list-calls',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Chamados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/calls-finished-cancels',
            name: 'list-calls-finish-cancels',
            component: Crud1,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Chamados Finalizados e Cancelados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/my-calls',
            name: 'list-my-calls',
            component: Crud2,

            meta: {
                pageTitle: 'Lista dos Meus Chamados',
                breadcrumb: [
                    {
                        text: 'Meus Chamados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/calls/view/:id?',
            name: 'viewCall',
            component: View,
            meta: {
                pageTitle: 'Chamados',
                breadcrumb: [
                    {
                        text: 'Chamados',
                        active: false,
                        to: '/calls',
                    },
                    {
                        text: 'Visualizar chamado',
                        active: true,
                    }
                ],
            },
        },
    ]
};


export default routes
