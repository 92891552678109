import Crud from '../views/Group/Crud'
import Entity from '../views/Group/Add'

let routes = {
    path: '/groups',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/groups/add',
            name: 'NewGroup',
            component: Entity,
            meta: {
                pageTitle: 'Grupo de Custo',
                breadcrumb: [
                    {
                        text: 'Grupo de Custo',
                        active: false,
                        to: '/groups',
                    },
                    {
                        text: 'Adicionar Grupo de Custo',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/groups/edit/:id?',
            name: 'EditGroup',
            component: Entity,
            meta: {
                pageTitle: 'Grupo de Custo',
                breadcrumb: [
                    {
                        text: 'Grupo de Custo',
                        active: false,
                        to: '/groups',
                    },
                    {
                        text: 'Editar Grupo de Custo',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/groups',
            name: 'list-groups',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Grupo de Custo',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
