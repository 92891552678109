import Crud from '../views/Team/Crud'
import Entity from '../views/Team/Add'

let routes = {
    path: '/teams',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/teams/add',
            name: 'NewUser',
            component: Entity
        },
        {
            path: '/teams/edit/:id?',
            name: 'EditTeam',
            component: Entity
        },
        {
            path: '/teams',
            name: 'list-teams',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Equipes',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
