import Index from '../views/Version/Index'
import IndexPackage from '../views/Version/IndexPackage'

let routes = {
    path: '/versions',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/versions',
            name: 'list-versions',
            component: Index,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Gerar Pacote de Versão',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/versions_package',
            name: 'list-versions-package',
            component: IndexPackage,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Gerar PDF de Versões',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
