<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-12 mb-1">
                <label>O que fez ontem?</label>
                <input
                  v-model="daily.yesterday"
                  class="form-control"
                  type="text"
                  :disabled="id ? true : false"
                />
              </div>
              <div class="col-md-12 mb-1">
                <label>O que vai fazer hoje?</label>
                <input
                  v-model="daily.today"
                  class="form-control"
                  type="text"
                  :disabled="id ? true : false"
                />
              </div>
              <div :class="'col-md-' + (status ? '3' : '12')">
                <label>Houve algum impedimento?</label>
                <select
                  class="form-control"
                  v-model="status"
                  :disabled="id ? 'true' : false"
                >
                  <option value="">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <div class="col-md-9" v-if="status">
                <label for="">Descreva-o por favor</label>
                <input
                  v-model="daily.impediment"
                  id="impedido"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-12 text-right" v-if="!id">
                <hr />
                <router-link to="/dailies" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      daily: {},
      status: "",
      id: 0,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "dailies";

      self.$loading(true);

      if (self.daily.id) {
        api += "/" + self.daily.id;
      }

      if (!self.status) {
        self.daily.impediment = "Não";
      } else {
        self.daily.impediment = "Sim, " + self.daily.impediment;
      }

      axios
        .post(api, self.daily)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          this.$router.push("/dailies");
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getDaily: function (id) {
      const self = this;
      const api = self.$store.state.api + "dailies/" + id;

      axios
        .get(api)
        .then((response) => {
          self.daily = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setView: function () {
      const self = this;
      self.id = self.$route.params.id;
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setView();
    if (id) {
      self.getDaily(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>