<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ team.id ? "Editar" : "Adicionar" }} Equipe
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="col-md-12">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="Name">
                    <span class="required">*</span> Nome:
                  </label>
                  <input
                    autocomplete="new-password"
                    type="text"
                    id="Name"
                    class="form-control"
                    v-model="team.name"
                    placeholder=""
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputUser">
                    <span class="required">*</span> Status:
                  </label>
                  <select v-model="team.status" class="form-control">
                    <option :value="1">Ativo</option>
                    <option :value="0">Inativo</option>
                  </select>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="card-header">
                  <div class="card-title">Vincular Usuários</div>
                </div>
                <div class="form-group col-md-12">
                  <label for="Name">
                    <span class="required">*</span> Usuários:
                  </label>
                  <v-select
                    v-model="selectedUsers"
                    :options="users"
                    :reduce="(user) => user.id"
                    label="name"
                    multiple
                  ></v-select>
                </div>
              </div>
              <span class="required_fields">
                <span class="required">*</span>
                <strong>Campos obrigatórios</strong>
              </span>
              <div class="block text-right mb-1">
                <router-link to="/teams" class="btn btn-back">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <a class="btn btn-add ml-1" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "imageUpload",
  data() {
    return {
      team: {
        name: "",
        status: "",
        users: [],
      },
      users: [],
      selectedUsers: [],
    };
  },
  methods: {
    save() {
      const self = this;
      let api = self.$store.state.api + "teams";

      if (self.team.id) {
        api += "/" + self.team.id;
        self.team._method = "PUT";
      }

      self.team.users = self.selectedUsers;

      axios
        .post(api, self.team)
        .then((response) => {
          self.$message(
            "Sucesso",
            "Informações guardadas com sucesso",
            "success"
          );
          self.$router.push("/teams");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTeam(id) {
      const self = this;
      const api = self.$store.state.api + "teams/" + id;

      axios
        .get(api)
        .then((response) => {
          self.team = response.data.data[0];
          self.getTeamUsers(self.team.id);
          self.selectedUsers = self.team.users;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers() {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTeamUsers: function (team_id) {
      const self = this;
      const api = self.$store.state.api + "team_users?team_id=" + team_id;

      axios
        .get(api)
        .then((response) => {
          self.selectedUsers = response.data.data.map((item) => item.user_id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getUsers();

    if (id) {
      self.getTeam(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
