import Crud from '../views/KnowledgeBase/Crud'
import Entity from '../views/KnowledgeBase/Add'
import View from '../views/KnowledgeBase/View'

let routes = {
    path: '/knowledge_bases',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/knowledge_bases/add',
            name: 'NewKnowledgeBase',
            component: Entity,
            meta: {
                pageTitle: 'Base de Conhecimento',
                breadcrumb: [
                    {
                        text: 'Base de Conhecimento',
                        active: false,
                        to: '/knowledge_bases',
                    },
                    {
                        text: 'Adicionar Base de Conhecimento',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/knowledge_bases/edit/:id?',
            name: 'EditKnowledgeBase',
            component: Entity,
            meta: {
                pageTitle: 'Base de Conhecimento',
                breadcrumb: [
                    {
                        text: 'Base de Conhecimento',
                        active: false,
                        to: '/knowledge_bases',
                    },
                    {
                        text: 'Editar Base de Conhecimento',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/knowledge_bases',
            name: 'list-knowledge-bases',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Base de Conhecimento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/knowledge_bases/view/:id?',
            name: 'viewKnowledgeBase',
            component: View,
            meta: {
                pageTitle: 'Base de Conhecimento',
                breadcrumb: [
                    {
                        text: 'Base de Conhecimento',
                        active: false,
                        to: '/knowledge_bases',
                    },
                    {
                        text: 'Visualizar Base de Conhecimento',
                        active: true,
                    }
                ],
            },
        },
    ]
};


export default routes
