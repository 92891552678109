<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'dailiesCrud'"
          crudName="Daily"
          newText="Nova Diária"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="false"
          :enableDelete="false"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          :customFilters="customFilters"
          @filtered_ds="filtered_ds"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "created_at_f",
          type: "calendar",
          call: "filtered_ds",
        },
      ],
      created_at: "",
      table: "dailiesTable",
      showMessageImport: false,
      dailiesCrud: {},
      pages: [],
      url: "",
      columns: [
        "id",
        "yesterday",
        "today",
        "impediment",
        "user_id",
        "created_at_f",
        "actions",
      ],
      tableData: [
        "id",
        "yesterday",
        "today",
        "impediment",
        "user_id",
        "created_at",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["yesterday", "today", "impediment", "user_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          yesterday: "Ontem",
          today: "Hoje",
          impediment: "Impedimento",
          user_id: "Usuário",
          created_at_f: "Data",
          actions: "Ações",
        },
        sortable: ["id", "user_id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Ativo",
            },
            {
              id: 0,
              text: "Inativo",
            },
          ],
        },
        templates: {
          status: function (h, row, index) {
            return row.status == 1
              ? "Ativo"
              : row.status == 0
              ? "Inativo"
              : "Inativo";
          },

          user_id: function (h, row, index) {
            return row.user ? row.user.name : "Indisponível";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.created_at != null &&
            this.$parent.$parent.$parent.created_at != ""
          ) {
            requestData.params.created_at =
              this.$parent.$parent.$parent.created_at;
          }

          requestData.params.query = "";
          requestData.params.with = ["user"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "dailies/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.created_at = date;
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        yesterday: "yesterday",
        today: "today",
        impediment: "impediment",
        user_id: "user[name]",
      };
      let filters = "&orderByDesc=id";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>