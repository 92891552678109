<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'teamsCrud'"
          crudName="Status dos Projetos"
          newText="Novo Status do Projeto"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "statusProjectTable",
      showMessageImport: false,
      dailiesCrud: {},
      pages: [],
      url: "",
      columns: ["id", "name", "actions"],
      tableData: ["id", "name"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["id", "name"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          name: "Nome",
          actions: "Ações",
        },
        sortable: ["id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {},
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.created_at != null &&
            this.$parent.$parent.$parent.created_at != ""
          ) {
            requestData.params.created_at =
              this.$parent.$parent.$parent.created_at;
          }

          requestData.params.query = "";
          requestData.params.with = ["user"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "status_projects/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.created_at = date;
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        name: "name",
      };
      let filters = "&orderByDesc=id";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>