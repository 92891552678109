<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Tipo de Despesa</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="col-md-12">
              <form action>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="Name">
                      <span class="required">*</span> Nome:
                    </label>
                    <input
                      autocomplete="new-password"
                      type="text"
                      id="Name"
                      class="form-control"
                      v-model="reimbursement_type.name"
                      placeholder=""
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputUser">
                      <span class="required">*</span> Status:
                    </label>
                    <select
                      v-model="reimbursement_type.status"
                      class="form-control"
                    >
                      <option :value="1">Ativo</option>
                      <option :value="0">Inativo</option>
                    </select>
                  </div>
                </div>
                <span class="required_fields">
                  <span class="required">*</span>
                  <strong>Campos obrigatórios</strong>
                </span>
                <div class="block text-right mb-1">
                  <router-link to="/reimbursement_types" class="btn btn-back">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add ml-1" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      reimbursement_type: {
        name: "",
        status: "",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "reimbursement_types";

      if (self.reimbursement_type.id) {
        api += "/" + self.reimbursement_type.id;
        self.reimbursement_type._method = "PUT";
      }

      axios
        .post(api, self.reimbursement_type)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/reimbursement_types");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getReimbursementTypes: function (id) {
      const self = this;
      const api = self.$store.state.api + "reimbursement_types/" + id;

      axios
        .get(api)
        .then((response) => {
          self.reimbursement_type = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getReimbursementTypes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>