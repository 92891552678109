<template>
  <div class="main-content">
    <div class="row">
      <div class="col-12">
        <div class="card p-1">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nome do PDF</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pdf in pdfs" :key="pdf.name">
                <td>{{ pdf.name }}</td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="downloadPDF(pdf.path)"
                  >
                    Download PDF
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "vue-pagination-2";

export default {
  data() {
    return {
      pdfs: [],
    };
  },
  components: {
    Pagination,
  },
  methods: {
    getPdfs() {
      const self = this;

      let api = self.$store.state.api + "get_pdfs";

      axios
        .get(api)
        .then((response) => {
          self.pdfs = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    downloadPDF(pdfPath) {
      window.open(pdfPath, "_blank");
    },
  },
  mounted() {
    this.getPdfs();
  },
};
</script>

<style scoped>
.main-content {
  padding: 20px;
}

.table th,
.table td {
  text-align: center;
}
</style>
