import Crud from '../views/BillCenter/Crud'
import Entity from '../views/BillCenter/Add'

let routes = {
    path: '/bill_centers',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/bill_centers/add',
            name: 'NewBillCenter',
            component: Entity,
            meta: {
                pageTitle: 'Centro de Custo',
                breadcrumb: [
                    {
                        text: 'Centro de Custo',
                        active: false,
                        to: '/bill_centers',
                    },
                    {
                        text: 'Adicionar Centro de Custo',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/bill_centers/edit/:id?',
            name: 'EditBillCenter',
            component: Entity,
            meta: {
                pageTitle: 'Centro de Custo',
                breadcrumb: [
                    {
                        text: 'Centro de Custo',
                        active: false,
                        to: '/bill_centers',
                    },
                    {
                        text: 'Editar Centro de Custo',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/bill_centers',
            name: 'list-bill_centers',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Centro de Custo',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
