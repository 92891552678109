<template>
  <div class="main-content">
    <div class="row mb-3">
      <div class="col-12 text-end">
        <button type="button" class="btn btn-primary" @click="openModalVersao">
          Gerar Pacote de Versão
        </button>
      </div>
      <div class="col-md-2 mt-2">
        <label>Titulo</label>
        <input type="text" class="form-control" v-model="filters.summary" />
      </div>
      <div class="col-md-2 mt-2">
        <label>Status</label>
        <select class="form-control" v-model="filters.deploy">
          <option value="0" selected>Implantar</option>
          <option value="1">Implantado</option>
          <option value="2">Homolog</option>
          <option value="3">Produção</option>
        </select>
      </div>
      <div class="col-md-3 mt-2">
        <label>Projeto</label>
        <v-select
          :options="projects"
          label="name"
          v-model="filters.project_id"
          :reduce="(project) => project.id"
        ></v-select>
      </div>
      <div class="col-md-2 mt-2">
        <label>Subir para o Ambiente</label>
        <select class="form-control" v-model="filters.environment">
          <option value="homolog">Homolog</option>
          <option value="production">Produção</option>
        </select>
      </div>
      <div class="row mt-3">
        <div class="col-md-2">
          <button class="btn btn-primary" @click="getIssues">Filtrar</button>
        </div>
      </div>
    </div>

    <!-- Modal Versão -->
    <div
      class="modal fade"
      id="modalVersao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalVersaoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="table-responsive">
              <table class="table table-striped text-center">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Título</th>
                    <th>Evidência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="issue in selectedIssuesDetails" :key="issue.id">
                    <td>{{ issue.id }}</td>
                    <td>
                      <textarea
                        cols="30"
                        rows="7"
                        class="form-control"
                        v-model="issue.summary"
                      ></textarea>
                    </td>
                    <td>
                      <ckeditor
                        v-model="issue.evidence"
                        :config="editorConfig"
                        :editor="editor"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateIssues">
              Salvar e abrir PDF
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card p-1">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col" v-if="showSelectColumn">Selecionar</th>
                <th scope="col">Título</th>
                <th scope="col">Sistema</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="issue in issues" :key="issue.id">
                <td>{{ issue.id }}</td>
                <td v-if="showSelectColumn">
                  <input
                    type="checkbox"
                    :value="issue.id"
                    v-model="selectedIssues"
                  />
                </td>
                <td>{{ issue.summary }}</td>
                <td>{{ issue.project_name }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
          >
            <pagination
              :records="total"
              :options="options"
              :per-page="15"
              @paginate="getIssues"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "vue-pagination-2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.intranet-dev.it4d.com.br/api/v1/images/upload",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  data() {
    return {
      projects: [],
      filters: {
        summary: "",
        project_id: "",
        deploy: 0,
        environment: "homolog",
      },
      issues: [],
      selectedIssues: [],
      selectedIssuesDetails: [],
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      pages: [],
      showSelectColumn: true,
      editor: ClassicEditor,
      editorConfig: {
        width: "800px",
        height: "400px",
        extraPlugins: [MyUploadAdapterPlugin],
      },
    };
  },
  components: {
    Pagination,
  },
  methods: {
    getIssues(page) {
      const self = this;

      if (!self.filters.project_id) {
        self.issues = [];
        self.total = 0;
        return;
      }

      self.page = page || 1;

      let api = self.$store.state.api + "get_versions?page=" + self.page;

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.issues = response.data.data;
          self.total = response.data.total;
          self.showSelectColumn = !self.issues.some(
            (issue) => issue.deploy === 1
          );
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    openModalVersao() {
      this.selectedIssuesDetails = this.selectedIssues.map((id) => {
        return this.issues.find((issue) => issue.id === id);
      });
      $("#modalVersao").modal("show");
    },
    generateVersionPackage() {
      const self = this;
      const api = self.$store.state.api + "generate_version";

      self.$loading(true);
      axios
        .post(api, {
          issues: self.selectedIssues.map((id) => {
            const issue = self.issues.find((issue) => issue.id === id);
            return {
              id: issue.id,
              summary: issue.summary,
              project_id: issue.project_id,
              deploy: issue.deploy,
            };
          }),
          environment: self.filters.environment,
        })
        .then((response) => {
          self.$message(
            "Success",
            "Pacote de versão gerado com sucesso",
            "success"
          );
          self.$loading(false);

          self.selectedIssuesDetails = self.selectedIssues.map((id) => {
            return self.issues.find((issue) => issue.id === id);
          });

          const downloadUrl = response.data.download_url;
          window.open(downloadUrl, "_blank");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getProjetos: function () {
      const self = this;
      let api = self.$store.state.api + "projects?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.projects = response.data.data;

          if (self.projects.length == 1) {
            self.entity.id = self.projects[0].id;
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    updateIssues() {
      const self = this;
      const api = self.$store.state.api + "update_issues";

      self.$loading(true);
      axios
        .post(api, {
          issues: self.selectedIssuesDetails.map((issue) => {
            return {
              id: issue.id,
              summary: issue.summary,
              evidence: issue.evidence,
            };
          }),
          deployStatus: self.filters.deploy,
          environment: self.filters.environment,
        })
        .then((response) => {
          self.$loading(false);
          self.$message("Success", "Issues atualizados com sucesso", "success");

          $("#modalVersao").modal("hide");
          self.generateVersionPackage();
          self.getIssues();
          self.selectedIssues = [];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  mounted() {
    this.getIssues();
    this.getProjetos();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.main-content {
  padding: 20px;
}

.table th,
.table td {
  text-align: center;
}
</style>
