<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card" :style="'border-left: 8px solid '">
            <div class="card-body">
              <div class="row">
                <div
                  class="d-flex justify-content-between flex-column col-xl-8 col-12"
                >
                  <div class="d-flex justify-content-start">
                    <label for="pacientePic" id="labelPacientePic">
                      <span
                        class="b-avatar badge-light-danger rounded btn"
                        style="width: 23vh; height: 23vh"
                        title="Alterar imagem"
                      >
                        <span class="b-avatar-img">
                          <img
                            class="btn-avatar"
                            alt="avatar"
                            id="pacienteImg"
                          />
                        </span>
                      </span>
                      <input
                        type="file"
                        name="pacientePic"
                        id="pacientePic"
                        class="d-none"
                      />
                    </label>
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <h4 class="mb-0" style="font-size: 26px">
                          {{ project.name }}
                        </h4>
                        <span
                          class="card-text"
                          style="font-size: 12px; margin-top: 4px"
                        >
                          <b>Key do Jira: </b>{{ project.jira_key }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-12 pl-0">
                  <table class="mt-2 mt-xl-0 w-100" style="font-size: 12px">
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-calendar mr-25"></i>
                        <span class="font-weight-bold">Data de início: </span>
                      </th>
                      <td class="pb-50">
                        {{
                          project.id ? project.created_at_f : "Não informado"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-user mr-25"></i>
                        <span class="font-weight-bold">Líder do sistema: </span>
                      </th>
                      <td class="pb-50">
                        {{ project.user.name }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-end">
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#modalTasks"
                  @click="getUsers"
                >
                  Adicionar tarefa
                </button>
              </div>

              <div
                class="modal fade"
                id="modalTasks"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalTasks"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Adicionar nova tarefa</h5>
                      <button type="button" class="close" data-dismiss="modal">
                        <span>&times;</span>
                      </button>
                    </div>
                    <div class="modal-body row">
                      <div class="col-md-12">
                        <label>Tarefa</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="task.title"
                        />
                      </div>
                      <div class="col-md-12">
                        <label>Descrição</label>
                        <textarea
                          class="form-control"
                          cols="30"
                          rows="3"
                          type="text"
                          v-model="task.description"
                        />
                      </div>
                      <div class="col-md-12">
                        <label>Responsável pela tarefa</label>
                        <v-select
                          v-model="task.dev_acting"
                          :options="users"
                          :reduce="(users) => users.id"
                          :label="'name'"
                        ></v-select>
                      </div>
                      <div class="col-md-6">
                        <label>Data de início</label>
                        <input
                          type="datetime-local"
                          class="form-control"
                          v-model="task.start_date"
                        />
                      </div>
                      <div class="col-md-6">
                        <label>Data final</label>
                        <input
                          type="datetime-local"
                          class="form-control"
                          v-model="task.end_date"
                        />
                      </div>

                      <div class="col-md-12">
                        <label>Status da tarefa</label>
                        <select class="form-control" v-model="task.status">
                          <option class="badge badge-secondary" value="0">
                            Backlog
                          </option>
                          <option class="badge badge-danger" value="1">
                            Impedido
                          </option>
                          <option class="badge badge-primary" value="2">
                            Em andamento
                          </option>
                          <option class="badge badge-success" value="3">
                            Concluído
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        @click="saveTask"
                      >
                        Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col-md-10">
                  <div class="mt-3">
                    <div
                      class="row bg mt-3"
                      style="text-align: left"
                      v-for="task_obj in tasks"
                      :key="task_obj.id"
                    >
                      <div class="col-md-12">
                        <strong class="text-center"
                          >Tarefa: {{ task_obj.title }}</strong
                        >
                        <br /><br />
                        <strong class="text-center"
                          >Descrição da tarefa:
                          {{ task_obj.description }}</strong
                        ><br /><br />
                        <strong class="text-center"
                          >Data de início: {{ task_obj.start_date_f }}</strong
                        ><br /><br />
                        <strong class="text-center"
                          >Data fim: {{ task_obj.end_date_f }}</strong
                        >
                        <br /><br />
                        <strong class="text-center"
                          >Responsável pela tarefa:
                          {{ task_obj.user.name }}</strong
                        >
                        <br /><br />
                        <strong class="text-center"
                          >Status da tarefa: {{ task_obj.status_f }}</strong
                        >
                        <br /><br />

                        <button
                          class="btn btn-add"
                          data-toggle="modal"
                          data-target="#modalTasks"
                          @click="editTask(task_obj.id)"
                        >
                          Editar tarefa
                        </button>
                      </div>
                    </div>
                    <div
                      class="row bg mt-3"
                      style="text-align: center"
                      v-if="tasks.length <= 0"
                    >
                      <div class="col-md-12">
                        <strong class="text-center"
                          >Nenhuma tarefa adicionada</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ShieldOffIcon } from "vue-feather-icons";
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      project: {
        name: "",
        jira_key: "",
        project_status_id: "1",
        user: {},
      },
      task: {
        user: {},
        status: "0",
        status: [
          {
            status: "Backlog",
            id: "0",
          },
        ],
      },
      tasks: [],
      users: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "projects";

      if (self.project.id) {
        api += "/" + self.projects.id;
      }

      axios
        .post(api, self.project)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/projects");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    saveTask: function () {
      const self = this;
      let api = self.$store.state.api + "tasks";
      self.task.project_id = self.project.id;

      if (self.task.id) {
        api += "/" + self.task.id;
        self.task._method = "PUT";
      }

      axios
        .post(api, self.task)
        .then((response) => {
          console.log(response);
          self.$message("Sucesso", `Tarefa adicionada`, "success");
          self.getTasks(self.project.id);

          self.task.title = "";
          self.task.description = "";
          self.task.responsible_task = "";
          self.task.start_date = "";
          self.task.end_date = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getProjects: function (id) {
      const self = this;
      const api = self.$store.state.api + "projects/" + id + "?with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.project = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users/";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

      self.task.title = "";
      self.task.description = "";

      self.task.start_date = "";
      self.task.end_date = "";
    },

    getTasks: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "tasks?project_id=" + id + "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.tasks = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    editTask: function (id) {
      const self = this;
      const api = self.$store.state.api + "tasks/" + id;

      axios
        .get(api)
        .then((response) => {
          self.task = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

      self.getUsers();
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    // self.getUsers();

    if (id) {
      self.getProjects(id);
      self.getTasks(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.bg {
  background-color: #ebebeb;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 20px;
}
</style>