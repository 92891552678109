<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1">
            <div class="row align-items-center">
              <div class="col-md-12 mb-1">
                <label>Grupo</label>
                <v-select
                  v-model="billCenter.group_id"
                  :options="groups"
                  :reduce="(groups) => groups.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-3 mb-1">
                <label>Número</label>
                <input
                  v-model="billCenter.number"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-9 mb-1">
                <label>Nome</label>
                <input
                  v-model="billCenter.name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12">
                <hr />
              </div>
              <div class="col-md-12">
                <h5>Fluxo de aprovação das DASPs:</h5>
              </div>
              <div class="col-md-6">
                <label>Usuário</label>
                <v-select
                  v-model="approver.id"
                  :options="users"
                  :reduce="(users) => users.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-2">
                <label>Tipo</label>
                <v-select
                  v-model="approver.type"
                  :options="type"
                  :reduce="(type) => type.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-2">
                <label>Ordem</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  v-model="approver.order"
                />
              </div>
              <div class="col-md-2">
                <button class="btn btn-add mt-2" @click="add">Adicionar</button>
              </div>
              <div class="col-md-12 mt-1">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center" width="45%">
                        Usuário
                      </th>
                      <th scope="col" style="text-align: center" width="15%">
                        Tipo
                      </th>
                      <th scope="col" style="text-align: center" width="15%">
                        Ordem
                      </th>
                      <th scope="col" style="text-align: center" width="15%">
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in approver" :key="index">
                      <td>{{ user.name }}</td>
                      <td style="text-align: center">
                        <span
                          @click="user.type < 3 ? user.type++ : (user.type = 1)"
                          :class="'badge badge-' + type[user.type - 1].class"
                          style="cursor: pointer; user-select: none"
                        >
                          {{ type[user.type - 1].name }}
                        </span>
                      </td>
                      <td style="text-align: center">
                        <input
                          type="number"
                          class="form-control"
                          min="0"
                          v-model="user.order"
                          @input="reorder()"
                        />
                      </td>
                      <td style="text-align: center">
                        <button
                          class="btn btn-crud delete"
                          @click="remover(index)"
                        >
                          <i
                            class="far fa-trash-alt"
                            data-toggle="tooltip"
                            title="Excluir"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="block text-right my-1">
              <router-link to="/bill_centers" class="btn btn-back">
                <i class="fa fa-arrow-left"></i> Voltar
              </router-link>
              <a class="btn btn-add ml-1" @click="save">
                Salvar
                <i class="fa fa-save"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      approver: [],
      approver_reimbursements: [],
      approver_reimbursement: {
        user_id: "",
        action: "",
        order: "",
      },
      billCenter: {},
      groups: [],
      users: [],
      type: [
        {
          id: 1,
          name: "Aprovação",
          class: "primary",
        },
        {
          id: 2,
          name: "Cotação",
          class: "warning",
        },
        {
          id: 3,
          name: "Compra",
          class: "success",
        },
      ],
      action_reimbursements: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    getActionClass(actionId) {
      const action = this.action_reimbursements.find(
        (action) => action.id === actionId
      );
      return action ? action.name : "";
    },
    getUserNameReimbursement(userId) {
      const user = this.users.find((user) => user.id === userId);
      return user ? user.name : "";
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "bill_centers_create";

      if (self.billCenter.id) {
        api += "/" + self.billCenter.id;
        self.billCenter._method = "PUT";
      }

      if (
        this.billCenter.group_id === "" ||
        this.billCenter.group_id === undefined
      ) {
        this.$message(null, "Você precisa inserir um Grupo", "error");
        return;
      }

      if (
        this.billCenter.number === "" ||
        this.billCenter.number === undefined
      ) {
        this.$message(null, "Você precisa digitar um Número", "error");
        return;
      }

      if (this.billCenter.name === "" || this.billCenter.name === undefined) {
        this.$message(null, "Você precisa digitar um Nome", "error");
        return;
      }

      const requestData = {
        billCenter: self.billCenter,
        approverReimbursements: self.approver_reimbursements,
        billCenterFlow: self.approver,
      };

      axios
        .post(api, requestData)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/bill_centers");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBillCenter: function (id) {
      const self = this;
      let api = self.$store.state.api + "bill_centers/" + id;

      axios
        .get(api)
        .then((response) => {
          self.billCenter = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getGroups: function () {
      const self = this;
      let api = self.$store.state.api + "groups";

      axios
        .get(api)
        .then((response) => {
          self.groups = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getActionReimbursements: function () {
      const self = this;
      let api = self.$store.state.api + "action_reimbursements";

      axios
        .get(api)
        .then((response) => {
          self.action_reimbursements = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getUsers: function () {
      const self = this;
      let api = self.$store.state.api + "users";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBillCenterReimbursements: function (bill_center_id) {
      const self = this;

      let api =
        self.$store.state.api +
        `approver_reimbursements/${bill_center_id}/bill_centers`;

      axios
        .get(api)
        .then((response) => {
          self.approver_reimbursements = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBillCenterFlows: function (id) {
      const self = this;
      let api =
        self.$store.state.api + `bill_center_flows?bill_center_id=${id}`;

      axios
        .get(api)
        .then((response) => {
          response.data.data.forEach((approver) => {
            let newEntry = {
              user_id: approver.user_id,
              name: self.getUserNameReimbursement(approver.user_id),
              order: approver.order,
              type: approver.action,
            };
            self.approver.push(newEntry);
          });
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    add() {
      if (this.approver.id === "" || this.approver.id === undefined) {
        this.$message(null, "Você precisa informar o usuário", "error");
        return;
      }

      if (this.approver.order === "" || this.approver.order === undefined) {
        this.$message(null, "Você precisa informar a ordem", "error");
        return;
      }

      this.getUserName(this.approver.id);

      const newEntry = {
        user_id: this.approver.id,
        name: this.approver.actual_name,
        order: this.approver.order,
        type: this.approver.type,
      };

      this.approver.push(newEntry); // adiciona o novo objeto ao array de datas

      this.approver.id = "";
      this.approver.order++;
      if (this.approver.type < this.type.length) {
        this.approver.type++;
      } else {
        this.approver.order = "";
        this.approver.type = "";
      }
      this.reorder();
    },
    remover(index) {
      this.approver.splice(index, 1);
    },
    getUserName: function (id) {
      this.users.forEach((user) => {
        if (user.id === id) {
          this.approver.actual_name = user.name;
        }
      });
    },
    reorder: function () {
      this.approver.sort(function (a, b) {
        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
      });
    },

    removerReimbursement(index) {
      this.approver_reimbursements.splice(index, 1);
    },

    reorderReimbursement: function () {
      this.approver_reimbursements.sort(function (a, b) {
        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
      });
    },

    addReimbursement() {
      const self = this;

      if (
        this.approver_reimbursement.user_id === "" ||
        this.approver_reimbursement.user_id === undefined
      ) {
        this.$message(null, "Você precisa informar o usuário", "error");
        return;
      }

      if (
        this.approver_reimbursement.order === "" ||
        this.approver_reimbursement.order === undefined
      ) {
        this.$message(null, "Você precisa informar a ordem", "error");
        return;
      }

      if (
        this.approver_reimbursement.action_id === "" ||
        this.approver_reimbursement.action_id === undefined
      ) {
        this.$message(null, "Você precisa informar o tipo de ação", "error");
        return;
      }

      const newApproverReimbursement = {
        id: self.billCenter.id,
        user_id: self.approver_reimbursement.user_id,
        action_id: self.approver_reimbursement.action_id,
        order: self.approver_reimbursement.order,
      };

      self.approver_reimbursements.push(newApproverReimbursement);

      self.billCenter.id = "";
      self.approver_reimbursement.user_id = "";
      self.approver_reimbursement.action_id = "";
      self.approver_reimbursement.order = "";

      console.log("aqui", newApproverReimbursement);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.$loading(true);
    self.getGroups();
    self.getUsers();
    self.getActionReimbursements();

    if (id) {
      self.getBillCenter(id);
      self.getBillCenterFlows(id);
      self.getBillCenterReimbursements(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>