<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'callsCrud'"
          crudName="Chamados"
          newText="Novo chamado"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="false"
          :enableEdit="false"
          :enableDelete="false"
          :enableDeleteReasons="false"
          :enableView="false"
          :enableReset="false"
          :enableTickets="false"
          :extra="extra"
          @viewFinished="viewFinished"
          :dataForm="dataForm"
        ></base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "callsTable",
      showMessageImport: false,
      callsCrud: {},
      pages: [],
      url: "",
      columns: [
        "id",
        "user_id",
        "title",
        "sla",
        "status",
        "dev_acting",
        "priority",
        "closed_at_f",
        "created_at_f",
        "system_error_id",
        "actions",
      ],
      tableData: [
        "id",
        "user_id",
        "title",
        "sla",
        "status",
        "dev_acting",
        "system_error_id",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["title", "status", "dev_acting", "system_error_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          user_id: "Solicitante",
          title: "Título",
          sla: "SLA",
          system_error_id: "Sistema",
          dev_acting: "Dev Responsável",
          priority: "Prioridade",
          created_at_f: "Data Abertura",
          closed_at_f: "Data Fechamento",
          actions: "Ações",
        },
        sortable: ["id", "status"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 9,
              text: "Cancelado",
            },
            {
              id: 12,
              text: "Finalizado",
            },
          ],
          priority: [
            {
              id: 1,
              text: "🔵 Ultra baixa prioridade",
            },
            {
              id: 3,
              text: "Aguardando Retorno",
            },
            {
              id: 2,
              text: "Homologação",
            },
            {
              id: 1,
              text: "Atendimento",
            },
            {
              id: 0,
              text: "Aberto",
            },
          ],
        },
        templates: {
          status: function (h, row, index) {
            return (
              <h5 class="text-center">
                <span class={row.status_calls.badge_description}>
                  {row.status_calls.description}
                </span>
              </h5>
            );
          },

          sla: function (h, row, index) {
            let response = row.sla.text;

            if (row.sla.proximity == 2) {
              return (
                <h5 class="text-center">
                  <span class="badge badge-warning">{response}</span>
                </h5>
              );
            } else if (row.sla.proximity == 3) {
              return (
                <h5 class="text-center">
                  <span class="badge badge-danger">{response}</span>
                </h5>
              );
            } else {
              return (
                <h5 class="text-center">
                  <span class="badge badge-success">{response}</span>
                </h5>
              );
            }
          },
          dev_acting: function (h, row, index) {
            if (row.status === 9) {
              return "Cancelado";
            } else {
              return row.dev ? row.dev.name : "-";
            }
          },
          user_id: function (h, row, index) {
            return row.user_model ? row.user_model.name : "-";
          },
          system_error_id: function (h, row, index) {
            return row.project ? row.project.name : "-";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "dev",
            "status_calls",
            "user_model",
            "project",
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud view",
          title: "Visualizar",
          name: "viewFinished",
          icon: "fas fa-eye",
        },
      ],
      endPoint: "calls-finished-cancels",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    viewFinished(props, row, index) {
      const self = this;
      this.$router.push("/calls/view/" + props.row.id);
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        status: "status",
        title: "title",
        dev_acting: "dev[name]",
        system_error_id: "project[name]",
      };
      let filters = "orderByDesc=id&status=9,12&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>