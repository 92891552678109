import Crud from '../views/ClientContact/Crud'
import Entity from '../views/ClientContact/Add'

let routes = {
    path: '/client_contacts',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/client_contacts/add',
            name: 'NewClientContact',
            component: Entity,
            meta: {
                pageTitle: 'Contato do Cliente',
                breadcrumb: [
                    {
                        text: 'Contato do Cliente',
                        active: false,
                        to: '/client_contacts',
                    },
                    {
                        text: 'Adicionar Contato do Cliente',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/client_contacts/edit/:id?',
            name: 'EditClientContact',
            component: Entity,
            meta: {
                pageTitle: 'Contato do Cliente',
                breadcrumb: [
                    {
                        text: 'Contato do Cliente',
                        active: false,
                        to: '/client_contacts',
                    },
                    {
                        text: 'Editar Contato do Cliente',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/client_contacts',
            name: 'list-client-contacts',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Contato do Cliente',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
