<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-4">
                <label>Nome</label>
                <input
                  v-model="project.name"
                  id="name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-4">
                <label>Key do Jira</label>
                <input
                  v-model="project.jira_key"
                  id="jira_key"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-4">
                <label>Clientes</label>
                <v-select
                  v-model="selectedClients"
                  :options="clients"
                  label="company_name"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Equipes</label>
                <v-select
                  v-model="selectedTeams"
                  :options="teams"
                  label="name"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Lider do sistema</label>
                <v-select
                  v-model="project.project_leader_id"
                  :options="users"
                  :reduce="(users) => users.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Tipo de sistema</label>
                <v-select
                  v-model="project.project_category_id"
                  :options="project_types"
                  :reduce="(project_types) => project_types.id"
                  :label="'title'"
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Status do sistema</label>
                <v-select
                  v-model="project.status_project_id"
                  :options="status_projects"
                  :reduce="(status_projects) => status_projects.id"
                  :label="'name'"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label for="inputUser">
                  <span class="required">*</span> Exibir no Dashboard:
                </label>
                <select
                  v-model="project.display_dashboard"
                  class="form-control"
                >
                  <option :value="0">Não</option>
                  <option :value="1">Sim</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Descrição</label>
                <input
                  v-model="project.description"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-6 mb-1">
                <label>Endpoint de Produção</label>
                <input
                  v-model="project.production_endpoint"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-6 mb-1">
                <label>Endpoint de Homologação</label>
                <input
                  v-model="project.homolog_endpoint"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-6">
                <label>Ínicio do Projeto</label>
                <input
                  v-model="project.start_date"
                  class="form-control"
                  type="date"
                />
              </div>
              <div class="col-md-6">
                <label>Fim do Projeto</label>
                <input
                  v-model="project.end_date"
                  class="form-control"
                  type="date"
                />
              </div>
              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/projects" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ShieldOffIcon } from "vue-feather-icons";
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      project: {
        name: "",
        jira_key: "",
        project_status_id: "1",
        display_dashboard: "0",
      },
      selectedClients: [],
      selectedTeams: [],
      users: [],
      project_types: [],
      clients: [],
      project_clients: [],
      teams: [],
      status_projects: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "projects";

      if (self.project.id) {
        api += "/" + self.project.id;
        self.project._method = "PUT";
      }

      self.project.clients = self.selectedClients.map((client) => client.id);
      self.project.teams = self.selectedTeams.map((team) => team.id);

      console.log(self.project);

      axios
        .post(api, self.project)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/projects");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjects: function (id) {
      const self = this;
      const api = self.$store.state.api + "projects/" + id;

      axios
        .get(api)
        .then((response) => {
          self.project = response.data.data[0];
          self.getProjectClients(self.project.id);
          self.selectedClients = self.project.clients;
          self.getProjectTeams(self.project.id);
          self.selectedTeams = self.project.teams;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getClients: function () {
      const self = this;
      const api = self.$store.state.api + "clients?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.clients = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getTeams: function () {
      const self = this;
      const api = self.$store.state.api + "teams?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.teams = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjecTypes: function () {
      const self = this;
      const api = self.$store.state.api + "project_types?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.project_types = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getStatusProjects: function () {
      const self = this;
      const api = self.$store.state.api + "status_projects?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.status_projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjectClients: function (project_id) {
      const self = this;
      const api =
        self.$store.state.api + "project_clients?project_id=" + project_id;

      axios
        .get(api)
        .then((response) => {
          self.selectedClients = response.data.data.map((item) => item.client);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjectTeams: function (project_id) {
      const self = this;
      const api =
        self.$store.state.api + "team_projects?project_id=" + project_id;

      axios
        .get(api)
        .then((response) => {
          self.selectedTeams = response.data.data.map((item) => item.team);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    self.getUsers();
    self.getProjecTypes();
    self.getClients();
    self.getTeams();
    self.getStatusProjects();

    if (id) {
      self.getProjects(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>