<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Cliente</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="col-md-12">
              <form action>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="Name">
                      <span class="required">*</span> Nome da empresa:
                    </label>
                    <input
                      autocomplete="new-password"
                      type="text"
                      id="Name"
                      class="form-control"
                      v-model="client.company_name"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="Cnpj"
                      ><span class="required">*</span> CNPJ:
                    </label>
                    <input
                      autocomplete="new-password"
                      class="form-control"
                      id="Cnpj"
                      placeholder=""
                      type="text"
                      v-model="client.cnpj"
                      v-mask="'99.999.999/9999-99'"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="Email">
                      <span class="required">*</span> Email:
                    </label>
                    <input
                      autocomplete="new-password"
                      class="form-control"
                      id="Email"
                      placeholder=""
                      type="email"
                      v-model="client.email"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="EmailDomain">Dominio do Email:</label>
                    <input
                      autocomplete="new-password"
                      class="form-control"
                      id="Email"
                      type="text"
                      v-model="client.email_domain"
                      placeholder=""
                      disabled
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label><span class="required">*</span> CEP</label>
                    <input
                      v-model="client.cep"
                      type="tel"
                      class="form-control"
                      v-mask="'99999-999'"
                      v-on:keyup="buscar"
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label><span class="required">*</span> Logradouro</label>
                    <input
                      v-model="client.street"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label><span class="required">*</span> Número</label>
                    <input
                      v-model="client.number"
                      type="text"
                      class="form-control"
                      id="inputNumero"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label><span class="required">*</span> Baírro</label>
                    <input
                      v-model="client.neighborhood"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label><span class="required">*</span> Estado</label>
                    <input
                      v-model="client.state"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label><span class="required">*</span> Cidade</label>
                    <input
                      v-model="client.city"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label>Complemento</label>
                    <input
                      v-model="client.complement"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label><span class="required">*</span> País</label>
                    <v-select
                      v-model="client.country"
                      :options="countries"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputUser">
                      <span class="required">*</span> Status:
                    </label>
                    <select v-model="client.status" class="form-control">
                      <option :value="1">Ativo</option>
                      <option :value="0">Inativo</option>
                    </select>
                  </div>
                </div>
                <span class="required_fields">
                  <span class="required">*</span>
                  <strong>Campos obrigatórios</strong>
                </span>
                <div class="block text-right mb-1">
                  <router-link to="/clients" class="btn btn-back">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add ml-1" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      client: {
        company_name: "",
        cnpj: "",
        email: "",
        cep: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        country: "",
        status: "",
      },
      countries: [],
    };
  },
  computed: {},
  watch: {
    "client.email": function (new_email) {
      if (new_email.includes("@")) {
        this.client.email_domain = new_email.split("@")[1];
      } else {
        this.client.email_domain = "";
      }
    },
  },
  methods: {
    buscarPaises: function () {
      const self = this;
      const apiUrl = "https://restcountries.com/v3.1/all?translations=pt";

      axios
        .get(apiUrl)
        .then((response) => {
          self.countries = response.data.map((country) => {
            if (country.name.nativeName && country.name.nativeName.por) {
              return country.name.nativeName.por.common + " " + country.flag;
            } else {
              return country.translations.por.common + " " + country.flag;
            }
          });
          self.countries.sort();

          self.countries = [...new Set(self.countries)];
        })
        .catch((error) => {
          console.error("Erro ao obter a lista de países", error);
        });
    },

    save: function () {
      const self = this;
      let api = self.$store.state.api + "clients";

      if (self.client.id) {
        api += "/" + self.client.id;
        self.client._method = "PUT";
      }

      axios
        .post(api, self.client)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/clients");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.client.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.client.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.client.street = self.endereco.logradouro;
            self.client.neighborhood = self.endereco.bairro;
            self.client.state = self.endereco.uf;
            self.client.city = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },
    getClient: function (id) {
      const self = this;
      const api = self.$store.state.api + "clients/" + id;

      axios
        .get(api)
        .then((response) => {
          self.client = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.buscarPaises();

    if (id) {
      self.getClient(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>